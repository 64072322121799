import ActivityImage from '../../components/activity/activityImage'
import './make-a-snowman.css'


function MakeaSnowman() {
 
  return (
    <div >
      

      <p><b>Ingredients </b></p>
      <ul>
        <li>1 cup bicarbonate of soda</li>
        <li>1 tablespoon salt (15ml)</li>
        <li>1 tablespoon hand wash liquid (clear or white)</li>
        <li>4 tablespoons water (60ml)</li>  
      </ul>

      <p><b>Method </b></p>
      <ul>
        <li>Mix all together in a bowl to create the snow.</li>
        <li>A marker, food coloring, paper or sweets can be used as decorations for a snowman.</li> 
      </ul>
      

      <div className='image-group'>
      <ActivityImage url="/uploads/152_Screenshot_20221122_083726_Gallery_55e7abbfe0.jpg?updated_at=2023-01-29T19:23:44.426Z" alt="snowman1" /> 
      <ActivityImage url="/uploads/151_Screenshot_20221122_083744_Gallery_1d04c2e73f.jpg?updated_at=2023-01-29T19:23:44.211Z" alt="snowman2" />
      <ActivityImage url="/uploads/150_Screenshot_20221122_083754_Gallery_be02c51672.jpg?updated_at=2023-01-29T19:23:43.878Z" alt="snowman3" />
      <ActivityImage url="/uploads/149_Screenshot_20221122_083851_Gallery_276f2ccda0.webp?updated_at=2023-01-29T19:23:43.961Z" alt="snowman4" />
     
      </div>
      <p>Little children who can’t build a snowman yet can just play with the snow. They can be exposed to different textures and it is a fun science experiment to do together. </p>

      <p>Recipe by The DAD lab : 
        <a href="https://www.facebook.com/thedadlab"> 
         <button className='button'>View Dad lab activities</button> 
        </a>
      </p>


    </div>
  )
}

export default MakeaSnowman
