import ActivityImage from '../../components/activity/activityImage'
import './tug-of-war.css'


function TugofWar() {
 
  return (
    <div >

      <p><b>Tug of war for little children</b></p>
      <p>Use a rope,cloth or a strong fabric. This can be a piece of cloth or a belt. It should be soft and easy to grip so that it is safe for them to play with.</p>
      <p>Their game can be a bit more informal where you slightly pull on the rope and let them have their turn. </p>
      <p>Ensure that the area behind them provides for a safe landing spot if they loose their balance.</p>

      <p><b>Tug or war for older children</b></p>
      <p>Make sure the rope is long enough for both teams to stand on either side with some slack in the middle.</p>
      <p>Mark the center point: Use a piece of tape or a marker to indicate the center point of the rope.</p>
      <p>Divide the teams</p>

      <p><b>Objective and rules if teams are playing</b></p>
       <ul>
       <li>Pull the rope so that the center point crosses the center line on your opponent's side.</li>
       <li>No one is allowed to use their hands to wrap the rope around their body or any other body part unless specified otherwise.</li>
       <li>If a player loses their grip on the rope, they must step away from the rope and let the game continue without them.</li>
       </ul>

      <ActivityImage url="/uploads/343_Screenshot_20221030_112849_800c9104e8.webp?updated_at=2023-02-13T18:17:14.023Z" alt="tugofwar" /> 
     
      <p>It can also be a two-player game for the fun of it!
      </p>











      

    </div>
  )
}

export default TugofWar
