import ActivityImage from '../../components/activity/activityImage'
import './send-parcels.css'


function SendParcels() {
 
  return (
    <div >
      
      
  <p>This entertaining activity can keep children busy for long. Children can send each other letters, toys, pictures or even snacks.</p>
  <p>Use a basket for transport.</p>
  <h3>Make your own basket</h3>
  <ul>
    <li>Take a piece of paper and fold it over multiple times to create a thick strip of paper.</li>
    <li>Attach it to a container with tape.</li>
    <li>Make sure that you secure the paper strip well enough to carry a decent load by making a few circles around the container with the tape.</li>
  </ul>
   <ActivityImage url="/uploads/328_Screenshot_20221101_222656_f17ebdd684.webp?updated_at=2023-01-29T19:23:26.705Z" alt="sendparcels1" />
  <p>Use a piece of rope or wool to create the link between two items. </p>
  <ul>
    <li>Pull one strand through the basket to make it a part of the travel system.</li>
    <li>There must be two rows of wool and the two loose ends must be tied together to form one long band that can be pulled from both sides.</li>
  </ul>
    <div className='image-group'>
      
      <ActivityImage url="/uploads/327_Screenshot_20221101_222613_aa2349a672.webp?updated_at=2023-01-29T19:23:26.654Z" alt="sendparcels2" />
      <ActivityImage url="/uploads/326_Screenshot_20221101_222627_014ff8f7eb.webp?updated_at=2023-01-29T19:23:26.404Z" alt="sendparcels3" />
      
      </div>

  <p>The children can each sit on one side of the contraption and can assist each other with the transportation of the basket. Have fun!</p>





        

    </div>
  )
}

export default SendParcels
