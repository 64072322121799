import ActivityImage from '../../components/activity/activityImage'
import './wooden-peg-puzzles.css'


function WoodenPegPuzzles() {
 
  return (
    <div >
      <p>Initially, they may only take the pieces out, but as the puzzle is repeatedly demonstrated to them, they will gradually attempt to fit the pieces in the correct places.</p>

      <p>It is recommended to begin with simple shapes that don't require excessive rotation, allowing them to practice grasping and replacing pieces.</p>
      
      <p>As they become proficient, more complex puzzles can be introduced, requiring additional rotation to achieve a proper fit.</p>
      
      <ActivityImage url="/uploads/Screenshot_20230203_100419_Gallery_1_768x712_1e18dab608.webp?updated_at=2023-02-14T16:43:01.099Z" alt="woodenpegpuzzle" /> 
     
      <p>They can do this activity on their own which will allow them some quiet time or you can teach them team work and tackle it with them!
      </p>










      

    </div>
  )
}

export default WoodenPegPuzzles
