import ActivityImage from '../../components/activity/activityImage'
import './finger-knitting.css'


function FingerKnitting() {
 
  return (
    <div >
     <p>Start by making a loop or tying a knot. There should be one loop that goes around the index finger.</p>
     <ul>
       <li>Fold the top loop over the botton left strand</li>
       <li>Pull the bottom left strand through the hole.</li>
     </ul>

     <div className='image-group'>
      <ActivityImage url="/uploads/179_Screenshot_20221116_202116_dad06e7044.jpg?updated_at=2023-01-29T19:23:47.125Z" alt="knot1" /> 
      <ActivityImage url="/uploads/175_Screenshot_20221116_202034_74bf38c0ac.jpg?updated_at=2023-01-29T19:23:46.494Z" alt="knot2" />
      <ActivityImage url="/uploads/174_Screenshot_20221116_202038_Gallery_fab868f8cb.jpg?updated_at=2023-01-29T19:23:46.136Z" alt="knot3" />
     </div>

     <p><b>Knitting process</b></p>
 
      <p><b>Step 1: </b> Put the index finger through the loop and put the longer wool strand over the top part of the index finger so that 2 lines are on the finger. </p>
      
      <div className='image-group'>
       <ActivityImage url="/uploads/173_Screenshot_20221116_202424_5de10c9dfd.webp?updated_at=2023-01-29T19:23:46.470Z" alt="fingerknit1" /> 
       <ActivityImage url="/uploads/172_Screenshot_20221116_202408_33261e71fe.webp?updated_at=2023-01-29T19:23:46.640Z" alt="fingerknit2" /> 
      </div>
      <p><b>Step 2: </b> Pull the bottom piece over the finger to leave one loop. Repeat step 1 and place wool over the top part of the finger</p>
      <div className='image-group'>
       <ActivityImage url="/uploads/171_Screenshot_20221116_202334_Gallery_fc55b2270f.webp?updated_at=2023-01-29T19:23:46.803Z" alt="fingerknit3" /> 
       <ActivityImage url="/uploads/170_Screenshot_20221116_202351_99624a1d46.jpg?updated_at=2023-01-29T19:23:46.005Z" alt="fingerknit4" /> 
      </div>

      <p>Alternate step 1 and 2. Place wool over the tip , pull the back piece over the finger. Place and pull over.</p>

      <p>To tie the end when they are finished, pull the long strand through the last hole.</p>
      <div className='image-group'>
       <ActivityImage url="/uploads/168_Screenshot_20221116_210633_8a6fdb1c67.jpg?updated_at=2023-01-29T19:23:45.639Z" alt="fingerknit5" /> 
       <ActivityImage url="/uploads/167_Screenshot_20221116_210643_e5d9babdd8.jpg?updated_at=2023-01-29T19:23:45.505Z" alt="fingerknit6" /> 
      </div>
       
      <p>The strands can be used to create a variety of items such as scarves, blankets, baskets, rugs, and even jewelry.</p> 
      <p>Children can make long strands and even try to let it go around the house!</p>










      

    </div>
  )
}

export default FingerKnitting
