import './activityDetail.css'
import {Outlet, useParams,} from 'react-router-dom'
import { useEffect, useState } from 'react'

import MeiliSearch from 'meilisearch'
import ActivityList from './ActivityList'
import { 
  FacebookShareButton, 
  WhatsappShareButton, 
  PinterestShareButton, 
  TwitterShareButton, 
  FacebookIcon, 
  WhatsappIcon,
PinterestIcon,
TwitterIcon } from 'react-share'
import ReactMarkdown from 'react-markdown'

function ActivityDetail() {

  const [activity, setActivity] = useState({})
  const params = useParams()

  useEffect(() => {
    const fetchData = async () => {
      const client = new MeiliSearch({
        host: "https://api.artsandparenting.com/meilli", //'https://ms-53118596bd8e-1995.lon.meilisearch.io',
        apiKey: "996c07ef10385217a247b3577edd2013534b742a"
      })
      const index = await client.getIndex('activity')
      const activityData = await index.getDocument('activity-'+params.id)
      setActivity(activityData)
    }
    fetchData()
  }, [params])


  let resource = activity.cover?.formats?.small?.url
  if(activity.cover?.formats?.small?.url === undefined){
    resource = activity.cover?.url
  }
  if(resource === undefined){
    resource = "/uploads/Screenshot_20221015_214904_1_1_768x697_60bf93a7ee.webp"
  }
  const shareUrl = 'https://master.d1havehehziue.amplifyapp.com/detail/'+activity.id+'/'+activity.filename
  
  return (
    <>
      <div className="activityDetail">
        <div className="card">
          <div className="card-image">
            {resource && <img src={"https://api.artsandparenting.com" + resource} alt="activity" />}

          </div>
          <div className="card-text">
            <h3>{activity.title}</h3>
            <p>{activity.subtitle}</p>
            <div className="share-buttons">
            <FacebookShareButton url={shareUrl}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <WhatsappShareButton url={shareUrl}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <PinterestShareButton media={shareUrl} url={shareUrl}>
              <PinterestIcon size={32} round />
            </PinterestShareButton>
            <TwitterShareButton url={shareUrl}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
          </div>
          </div>
        </div>
        <ReactMarkdown className="activity-body" children={activity.body} />
        {/* <div className="content">
          <Outlet />
        </div> */}
      </div>
      <ActivityList searchValue={""} excludeId={activity.id}></ActivityList>
    </>
  );
}

export default ActivityDetail
