import ActivityImage from '../../components/activity/activityImage'
import './build-a-bridge.css'


function BuildaBridge() {
 
  return (
    <div >
      <p>This is a fun activity that children can do under supervision.</p>

      <p><b>Benefits of the activity</b></p>
      <ul>
      <li>It improves logical thinking and the ability to overcome challenges.</li>
      <li>It develops precise hand movements and the ability to coordinate your actions.</li>
      <li>It helps children to develop their patience.</li>
      <li>Teaches them an understanding of the spatial relationships between the different parts of the bridge and how they will support the weight of the bridge and any objects crossing it.</li>
      </ul>
      
      <p><b>Sticks</b></p>
      <p>Kebab sticks, toothpicks, sticks collected outside or even ice cream sticks can be used.</p>

      <p><b>Glue</b></p>
      <p>The material that holds the sticks together can be anything from marshmallows, clay and Prestik to duct tape. Children can build structures and see how strong they can make it or even how high it can go.</p>
      
      <div className='image-group'>
      <ActivityImage url="/uploads/307_Screenshot_20221103_204211_cf811e6955.jpg?updated_at=2023-01-29T19:23:23.002Z" alt="stickbridge1" /> 
      <ActivityImage url="/uploads/306_Screenshot_20221103_195521_dc87da1e4a.webp?updated_at=2023-01-29T19:23:23.424Z" alt="stockbridge2" />
      </div>

      <p><b>Hints</b></p>
      <ul>
      <li>Plan your bridge before you start building.</li>
      <li>Test the strength and flexibility of your sticks.</li>
      <li>Make sure the base of your bridge is sturdy.</li>
      <li>Use strong and flexible sticks.</li>
      <li>Mix and match different sticks to create a diverse bridge.</li>
      <li>Test your bridge as you build it.</li>
      <li>Use caution and make sure the bridge is safe before using it.</li>
      </ul>




      

    </div>
  )
}

export default BuildaBridge
