import ActivityImage from '../../components/activity/activityImage'
import './action-paint.css'


function ActionPaint() {
 
  return (
    <div >
      <p>This is a spontaneous activity where paint is splashed, dribbled, or smeared onto paper. </p>
      <p>To make it easier, the paint can be diluted with water and applied by standing up and splashing it on the paper or flicking a brush.</p> 
      <p>It's important to ensure that the area is big enough and easy to clean, especially when involving children as the excitement can lead to a lot of splashing!</p>
      
      
      <div className='image-group'>
      <ActivityImage url="/uploads/Screenshot_20220904_111910_Gallery_300x295_8d348a3588.jpg?updated_at=2023-03-05T18:21:30.341Z" alt="actionpaint1" /> 
      <ActivityImage url="/uploads/Screenshot_20220904_120336_Gallery_300x256_2a85a060aa.jpg?updated_at=2023-03-05T18:21:34.499Z" alt="actionpaint2" />
     
      </div>
     
     <p>Have fun!</p>











      

    </div>
  )
}

export default ActionPaint
