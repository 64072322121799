import './searchBar.css'

function SearchBar({searchValue, setSearchValue}) {
  return (
    <div className='searchContainer'>
      <form className='search' >
        <input 
          type='text'
          placeholder='Search'
          value={searchValue}
  
          onChange={e => setSearchValue(e.target.value)}/>
          {searchValue.length >= 1 &&
            <span 
              className='search__cancel'
              onClick={() => setSearchValue('')}>x</span>
          }
      </form>
    </div>
  )
}

export default SearchBar