import ActivityImage from '../../components/activity/activityImage'
import './diamond-painting.css'


function DiamondPainting() {
 
  return (
    <div >

      <p>The tools that are used include:
        <ul>
          <li>the canvas board</li>
          <li>a diamond painting pen</li>
          <li>wax to assist with the picking up of diamonds</li>
          <li>a sorting tray and a few packets of diamonds</li>
        </ul> </p>
      <p>Keep the film over the areas of the canvas that have not yet been worked on to ensure that the glue does not dry.</p>
    
      <p><b>Instructions </b> </p>
      <ul>
        <li>Apply the wax to the diamond pen by pressing with the pen on the wax. If the diamonds have trouble sticking to the pen when picked up, then apply more wax.</li>
        <li>The tray will assist with the sorting of the diamond to let it face upwards. Simply add a few diamonds to the tray, tilt the tray slightly and shake it slightly from left to right. The diamonds will gradually flip over and stay in place.</li>
        <li>Apply the diamonds by pressing on the canvas with the diamond pen.</li>

      </ul>

      <div className='image-group'>
      <ActivityImage url="/uploads/160_Screenshot_20221120_060755_3e896705fb.jpg?updated_at=2023-01-29T19:23:44.948Z" alt="diamond painting" /> 
      <ActivityImage url="/uploads/159_Screenshot_20221120_060800_Gallery_810ebd04a8.jpg?updated_at=2023-01-29T19:23:44.927Z" alt="diamond painting2" />
      <ActivityImage url="/uploads/158_Screenshot_20221120_070256_3c1d7aa1f9.jpg?updated_at=2023-01-29T19:23:44.658Z" alt="diamond painting3" />
      <ActivityImage url="/uploads/156_Screenshot_20221120_070341_72e710e11a.jpg?updated_at=2023-01-29T19:23:44.633Z" alt="diamond painting4" />
      <ActivityImage url="/uploads/155_Screenshot_20221120_070354_4f3bdb07d7.jpg?updated_at=2023-01-29T19:23:44.550Z" alt="diamond painting5" />
      <ActivityImage url="/uploads/154_Screenshot_20221120_070427_a96458f17b.jpg?updated_at=2023-01-29T19:23:44.601Z" alt="diamond painting6" />
      </div>
      <p>Have fun!
      </p>











      

    </div>
  )
}

export default DiamondPainting