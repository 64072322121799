import './paper-weaving.css'

import ActivityImage from '../../components/activity/activityImage'

function PaperWeaving() {
 
  return (
    <div >
      <p>The activity is simple, it involves cutting paper strips in different colors and patterns, and then weaving them together to create a unique design. </p>

      <p>Benefits of this activity:</p>
      <ul>
        <li>Improving their fine motor skills and hand-eye coordination</li>
        <li>Expressing their creativity</li>
        <li>Solving problems and using their imagination</li>
        <li>Learning patience and focus</li>
        <li>Understanding the importance of recycling</li>
        <li>Having fun and socializing with others</li>
      </ul>

      <h3>Method:</h3>

      <p><b>Step 1:</b> Gather materials: You will need paper strips (construction paper, newspaper, magazine pages, or any kind of paper you have on hand), scissors, and a base for weaving (cardboard, foam board, or a cardboard ring).</p> 
      <p><b>Step 2:</b> Cut paper strips: Cut the paper into strips of different widths and colors. You can cut them straight or with a zigzag pattern.</p> 
      <ActivityImage url='/uploads/35_Screenshot_20230121_112807_Gallery_ade9daef0e.jpg?updated_at=2023-01-29T19:24:04.605Z' alt='Cut strings' />

      <p><b>Step 3:</b> Start weaving: Take the first strip of paper and place it horizontally across your base. Take a second strip of paper and place it vertically over the first strip, then tuck the end of the second strip under the first strip.</p> 
      <div className="image-group"> 
        <ActivityImage url='/uploads/34_Screenshot_20230121_112817_Gallery_cb218c8b55.jpg?updated_at=2023-01-29T19:24:04.577Z' alt='Weave paper' />
        <ActivityImage url='' alt='Weave paper' />
        <ActivityImage url='/uploads/32_Screenshot_20230121_112834_Gallery_dea29aea91.jpg?updated_at=2023-01-29T19:24:03.980Z' alt='Weave paper' />
      </div>
      <p><b>Step 4:</b> Continue weaving: Take the next strip of paper and place it horizontally across the base, tucking the end under the vertical strip. Alternate between vertical and horizontal strips, weaving them together to create a pattern.</p> 
      <ActivityImage url='/uploads/31_Screenshot_20230121_112844_Gallery_6841efae04.jpg?updated_at=2023-01-29T19:24:03.816Z' alt='Weave paper' />
      <p><b>Step 5:</b> Secure the end: Once you reach the end of the weaving process, secure the last strip by tucking it under the previous strip.</p>
      <div className="image-group"> 
        <ActivityImage url='/uploads/27_Screenshot_20230121_112854_Gallery_1_072ca939e9.jpg?updated_at=2023-01-29T19:24:03.222Z' alt='Weave paper' />
        <ActivityImage url='/uploads/26_Screenshot_20230121_112936_Gallery_d0a3ec004d.webp?updated_at=2023-01-29T19:24:03.253Z' alt='Weaved paper final' />
        <ActivityImage url='/uploads/30_Screenshot_20230121_112912_Gallery_a423ad1380.webp?updated_at=2023-01-29T19:24:04.293Z' alt='Weave paper with glue and scissor' />
      </div>
      You can change the size of the strips, the color, the pattern, and the base to make the paper weaving more challenging or more fun, depending on the children’s age and skills.
    </div>
  )
}

export default PaperWeaving
