import ActivityImage from '../../components/activity/activityImage'
import './musical-instruments.css'


function MusicalInstruments() {
 
  return (
    <div >
      <p><b>Benefits of playing with musical instruments</b></p>
      <ul>
        <li>Enhanced coordination.</li>
        <li>Increased memory capacity.</li>
        <li>It teaches them perserverance.</li>
      </ul>
      <ActivityImage url="/uploads/181_Screenshot_20221113_190445_cc4b345682.webp?updated_at=2023-01-29T19:23:47.848Z" alt="musicalinstruments" /> 

      <p><b>They can make their own instruments at home</b></p>
 
      <p><b>Drums:</b> use a container with a lid that they can bang on. If they add objects to the container the sound will change. They can also shake it.</p>

      <p><b>Bells:</b> use metal caps from bottles and make holes in the middle. Pull some string or wire through and connect the sides so make bells that they can shake.</p>

      <p><b>Drumsticks:</b> use sticks collected outside, pens, knitting needles or eating utensils as drumsticks.</p>

      <p><b>Xylophone:</b> materials : metal or plastic spoons, cardboard box, duct tape, small rubber bands or pieces of string. Instructions: place the spoons in a row on top of the cardboard box. The longer the spoon, the lower the pitch of the note it will make. Use the duct tape to secure the spoons to the cardboard box. Make sure the spoons are spaced evenly apart. Wrap a rubber band or piece of string around the handle of each spoon. The tighter the rubber band, the higher the pitch of the note. To play the xylophone, hit the spoons with your hands or drumsticks. Experiment with different pitches by adjusting the rubber bands or string. </p>



      
     










      

    </div>
  )
}

export default MusicalInstruments
