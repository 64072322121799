import ActivityImage from '../../components/activity/activityImage'
import './make-waffles.css'


function MakeWaffles() {
 
  return (
    <div >
       <p>Different toppings can be added like a caramel sauce (see recipe below), ice cream, cream, syrup, cheese, and different fruits.</p>
  <h3>Waffle recipe (makes 4-5 waffles)</h3>
  <ul>
    <li>1 cup flour (250ml)</li>
    <li>1 teaspoon (5ml) baking powder</li>
    <li>pinch of salt</li>
    <li>1 tablespoon (15ml) sugar</li>
    <li>2 tablespoons (30ml) melted butter</li>
    <li>240ml milk</li>
    <li>1 egg</li>
  </ul>
  <h4>Method</h4>
  <ul>
    <li>Mix all the ingredients together and pour about half a cup of the batter onto the preheated waffle iron and spread it evenly.</li>
    <li>Cook the waffles for 2-5 minutes depending on how hard/soft you would like it to be.</li>
    <li><b>Tip:</b> if the waffles are to be eaten later, let it cool while leaning upright for a while to prevent it from becoming soggy.</li>
    <li>Add the toppings and enjoy!</li>
  </ul>

 <ActivityImage url="/uploads/406_Screenshot_20221026_205241_daf5324e46.webp?updated_at=2023-01-29T19:23:08.374Z" alt="waffles1" /> 

  <h3>Caramel recipe (to be made by an adult as it contains hot sugar and water)</h3>
  <ul>
    <li>1 cup of sugar (250ml)</li>
    <li>125g butter (125ml also works)</li>
    <li>2 cups warm water</li>
    <li>5mls vanilla</li>
    <li>4 tablespoons maizena (+/-90ml)</li>
  </ul>
  <h4>Method</h4>
  <ul>
    <li>Add the cup of sugar and 125ml butter to a pot and cook on medium heat while stirring until it becomes a caramel color.</li>
    <li>Add the 2 cups of warm water. It will boil and make a lot of steam. Keep on stirring until the clumps of sugar dissolve.</li>
    <li>Dissolve the maizena in some cold water and add it to the pot with the vanilla. Keep on stirring for about 5 minutes or until the sauce reaches the desired consistency.</li>
    <li>Let it cool a bit while making the waffles and pour it on top. Enjoy!</li>
  </ul>







      <div className='image-group'>
     
      <ActivityImage url="/uploads/411_Screenshot_20221026_205103_895f923332.webp?updated_at=2023-01-29T19:23:09.473Z" alt="waffles2" />
      <ActivityImage url="/uploads/410_Screenshot_20221026_205123_567fb53d82.webp?updated_at=2023-01-29T19:23:09.580Z" alt="waffles3" />
      <ActivityImage url="/uploads/409_Screenshot_20221026_205137_9aec2b1883.webp?updated_at=2023-01-29T19:23:09.131Z" alt="waffles4" />
      <ActivityImage url="/uploads/408_Screenshot_20221026_205146_56a3757180.webp?updated_at=2023-01-29T19:23:08.734Z" alt="waffles5" />
      <ActivityImage url="/uploads/404_Screenshot_20221026_205232_c63d4378ca.webp?updated_at=2023-01-29T19:23:07.972Z" alt="waffles6" />
      </div>

   











      

    </div>
  )
}

export default MakeWaffles
