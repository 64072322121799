import ActivityImage from '../../components/activity/activityImage'
import './water-siphon-experiment.css'


function WaterSiphonExperiment() {
 
  return (
    <div >
      <p>The liquid will travel upwards above the water level of the container and then downwards into another container without using a pump.</p>
      
      <p>Gauze or wet wipes can be used as a water transport medium.</p>

      <p>The water can be colored to help demonstrate the effect</p>
      <p>The first glass has to be placed on an elevation so that it is higher than the second glass. Fill it up with water. Ensure that the gauze or wipe is submerged in the liquid before placing the other part in the second glass. The liquid will continue to flow from one glass to the other.</p>
      <div className='image-group'>
      <ActivityImage url="/uploads/190_Screenshot_20221113_181428_cca01cf00a.webp?updated_at=2023-01-29T19:23:41.299Z" alt="siphon1" /> 
      <ActivityImage url="/uploads/188_Screenshot_20221113_181646_Gallery_5cb9a29c3a.jpg?updated_at=2023-01-29T19:23:40.506Z" alt="siphon2" />
      <ActivityImage url="/uploads/187_Screenshot_20221113_181700_76a7ebf39c.webp?updated_at=2023-01-29T19:23:40.564Z" alt="siphon3" />
      <ActivityImage url="/uploads/186_Screenshot_20221113_181728_2e57cfb8a8.webp?updated_at=2023-01-29T19:23:40.458Z" alt="siphon4" />
     
      </div>
      <p>The siphon can also be used as a water filtering system. Simply add some soil to the first container and see how the clean water gets transferred to the second container and the soil remains behind.</p>


      










      

    </div>
  )
}

export default WaterSiphonExperiment
