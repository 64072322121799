import ActivityImage from '../../components/activity/activityImage'
import './make-fridge-magnets.css'


function MakeFridgeMagnets() {
 
  return (
    <div >
      <p>The magnetic strips can be purchaced from an e-commerce site or Arts and Crafts stores.</p>

      <p>Personal drawings, stickers, toys and picture cut-outs can be used to make magnets.</p>

      <p>Cut the magnetic strip into the desired size and peel off the paper. Stick the magnetic piece to the back of the item that you want to use.</p>
      <p>Use larger magnet strips for heavier items to ensure that it sticks!</p>
      <div className='image-group'>
      <ActivityImage url="/uploads/59_Screenshot_20221231_195927_Gallery_815682788c.webp?updated_at=2023-01-29T19:23:59.073Z" alt="magnets1" /> 
      <ActivityImage url="/uploads/58_Screenshot_20221231_195957_Gallery_5ddc671024.webp?updated_at=2023-01-29T19:23:58.782Z" alt="magnets2" />
      <ActivityImage url="/uploads/57_Screenshot_20221231_200008_Gallery_db4126f61b.webp?updated_at=2023-01-29T19:23:58.458Z" alt="magnets3" />
      <ActivityImage url="/uploads/54_Screenshot_20221231_195944_Gallery_fbf6543f63.jpg?updated_at=2023-01-29T19:23:57.258Z" alt="magnets4" />
     
      </div>
      <p>Have fun!</p>
      
      











      

    </div>
  )
}

export default MakeFridgeMagnets
