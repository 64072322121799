import ActivityImage from '../../components/activity/activityImage'
import './oats-and-coconut-snack.css'


function OatsandCoconutSnack() {
 
  return (
    <div >


      
      <h1>Recipe</h1>
  <ul>
    <li>1/2 cup butter (125ml)</li>
    <li>1/2 cup cocoa (125ml)</li>
    <li>1/4 cup milk (60ml)</li>
    <li>1 cup sugar (250ml)</li>
    <li>1 cup fine coconut flakes (250ml)</li>
    <li>3 cups jungle oats (750ml)</li>
  </ul>
<p>Place foil in a dish and measure out the coconut and jungle oats beforehand</p>
      <ActivityImage url="/uploads/Screenshot_20220905_182338_Gallery_768x764_862a71c232.jpg?updated_at=2023-03-21T17:45:22.479Z" alt="oatssnack1" /> 


  <p><b>Step 1: </b>Add the butter, cocoa, milk and sugar to a pot and cook on medium heat for 5 minutes while stirring</p>
  <p><b>Step 2:</b> Remove from heat and add 3 cups jungle oats and 1 cup fine coconut. Mix and distribute it evenly</p>
  <p><b>Step 3: </b>Transfer the contents to the pyrex dish and flatten it with a spoon</p>
  <p>Allow to harden by leaving it in the fridge for a few minutes then cut it into squares</p>
 




      <div className='image-group'>
      <ActivityImage url="/uploads/Screenshot_20220905_182235_Gallery_768x764_dc347d77d6.jpg?updated_at=2023-03-21T17:45:26.836Z" alt="oatssnack2" /> 
      <ActivityImage url="/uploads/Screenshot_20220905_182248_Gallery_768x702_6b42da9aba.jpg?updated_at=2023-03-21T17:45:30.628Z" alt="oatssnack3" /> 
      <ActivityImage url="/uploads/Screenshot_20220905_182353_Gallery_768x764_6bbdc4e94c.jpg?updated_at=2023-03-21T17:45:33.290Z" alt="oatssnack4" /> 
      <ActivityImage url="/uploads/Screenshot_20220905_182405_Gallery_768x764_6d46cd310b.jpg?updated_at=2023-03-21T17:45:36.648Z" alt="oatssnack5" /> 
      <ActivityImage url="/uploads/Screenshot_20220905_182427_Gallery_768x724_f26a619958.jpg?updated_at=2023-03-21T17:45:40.069Z" alt="oatssnack6" /> 
      <ActivityImage url="/uploads/Screenshot_20220905_182503_Gallery_768x764_84699d9485.jpg?updated_at=2023-03-21T17:45:43.448Z" alt="oatssnack7" /> 

       <p>Serve and enjoy!</p>
      </div>
      




      

    </div>
  )
}

export default OatsandCoconutSnack
