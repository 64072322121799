import ActivityImage from '../../components/activity/activityImage'
import './three-dimensional-puzzles.css'


function ThreeDimensionalPuzzles() {
 
  return (
    <div >
      <p>There are a variety of designs available which includes animals, vehicles or landmarks.</p>
      
      <p>Some of the puzzles are pre-painted and others can be painted at home. </p>
      <p>The pieces can be glued together if it does not stay in place.</p>
      
      <div className='image-group'>
      <ActivityImage url="/uploads/197_Screenshot_20221112_190039_cbe5b95e46.jpg?updated_at=2023-01-29T19:23:42.217Z" alt="3dpuzzle1" /> 
      <ActivityImage url="/uploads/198_Screenshot_20221112_190021_cf10fb36b8.jpg?updated_at=2023-01-29T19:23:42.099Z" alt="3dpuzzle2" />
      <ActivityImage url="/uploads/196_Screenshot_20221112_190236_da4de0dc90.jpg?updated_at=2023-01-29T19:23:42.061Z" alt="3dpuzzle3" />
      </div>
      <p>Have fun!
      </p>











      

    </div>
  )
}

export default ThreeDimensionalPuzzles
