import ActivityImage from '../../components/activity/activityImage'
import './animal-wrapping.css'


function AnimalWrapping() {
 
  return (
    <div >
     <p>Regular gift paper can be used or white paper with pictures and notes drawn by the children if they want to personalize it.</p>

     <p><b>Wrap the present first then use some paper or newspaper that has been rolled into a ball for the head </b></p>
     <ActivityImage url="/uploads/73_Screenshot_20221217_193223_Gallery_8f205fc85c.webp?updated_at=2023-01-29T19:24:01.931Z" alt= "animalwrap1"/>  

     <p><b>Wrap the head</b></p>
     <ActivityImage url="/uploads/72_Screenshot_20221217_193214_Gallery_33484cf5a3.webp?updated_at=2023-01-29T19:24:01.828Z" alt= "animalwrap2"/>

     <p><b>Attach the head to the body with tape</b></p>
     <ActivityImage url="/uploads/71_Screenshot_20221217_193205_Gallery_26120b9a47.webp?updated_at=2023-01-29T19:24:01.651Z" alt= "animalwrap3"/>

     <p><b>Make some feet by rolling paper into balls and wrapping it or by just using gift wrapping paper </b></p>
     <ActivityImage url="/uploads/69_Screenshot_20221217_193147_Gallery_54c6fdd87f.webp?updated_at=2023-01-29T19:24:01.281Z" alt= "animalwrap4"/>

     <p><b>Attach the feet to the body by using tape </b></p>
     <ActivityImage url="/uploads/68_Screenshot_20221217_193138_Gallery_95619b30f4.webp?updated_at=2023-01-29T19:24:00.820Z" alt= "animalwrap5"/>

     <p><b>Cut out ears and a tail </b></p>
     <ActivityImage url="/uploads/67_Screenshot_20221217_193129_Gallery_de75c03de0.webp?updated_at=2023-01-29T19:24:00.315Z" alt= "animalwrap6"/>

     <p><b>Lift the ears and place the tape over the bottom part so that when it gets flipped over it will hang with an arch </b></p>
     <ActivityImage url="/uploads/65_Screenshot_20221217_193111_Gallery_b430fd2048.webp?updated_at=2023-01-29T19:24:00.591Z" alt= "animalwrap7"/>

     <p><b>Add the other ear, tail, and a face. The face can be pasted or drawn on </b></p>
     <ActivityImage url="/uploads/64_Screenshot_20221217_193030_Gallery_a8aca833aa.webp?updated_at=2023-01-29T19:23:59.963Z" alt= "animalwrap8"/>
     <p>Different figures can be made like cats, fish, bunnies, and even a snow man. Have fun! </p>
     <ActivityImage url="/uploads/60_Screenshot_20221220_122644_Gallery_7e29f231af.webp?updated_at=2023-01-29T19:23:59.360Z" alt= "animalwrap9"/>
  

  
    </div>

  )

}

export default AnimalWrapping
