import ActivityImage from '../../components/activity/activityImage'
import './play-with-a-frisbee.css'


function PlaywithaFrisbee() {
 
  return (
    <div >
      <p>This is a good way to practise hand-eye coordination.</p>

      <p><b>Activities with a frisbee</b></p>
      <ul>
        <li>Regular throw and catch</li>
        <li>Middle man: Two players stand far away from each other with the rest of the players in the middle. They must try to throw the frisbee over or inbetween them to one another. If another player catches the frisbee then they can swap places with the one that threw the frisbee. </li>
        <li>Frisbee Golf: Create a "course" using obstacles like trees, bushes, or rocks, and designate a target, such as a trash can or a designated area. Each player takes turns throwing the frisbee toward the target, counting the number of throws it takes to get there. The player with the fewest throws at the end of the game wins.</li>
        <li>Frisbee Bowling: Set up plastic bottles or bowling pins in a triangular formation. Take turns throwing the frisbee at them. Each player gets two throws per turn. The player who knocks down the most pins wins.</li>
      </ul>
      <ActivityImage url="/uploads/Screenshot_20221028_210553_c81a4f26de.webp?updated_at=2023-03-05T17:50:35.508Z" alt="frisbee1" />
      <p>Remember to have enough space for the game. Have fun!
      </p>











      

    </div>
  )
}

export default PlaywithaFrisbee
