import ActivityImage from '../../components/activity/activityImage'
import './complete-a-maze.css'


function CompleteaMaze() {
 
  return (
    <div >
      <p>There are wonderful maze activity books for children. Children can also draw their own mazes at home.</p>
      
      <p><b>Benefits of this activity</b></p>
      <ul>
        <li>Mental exercise: Completing a maze requires problem-solving skills and spatial awareness, which can provide a mental workout and improve cognitive function.</li>
        <li>Relaxation: Some people find completing mazes to be a calming and therapeutic activity, which can help reduce stress and promote relaxation.</li>
      </ul>
  
      <p>Create a maze for them. Objects and colour can be used to make it more interesting.</p>
      
      <div className='image-group'>
      <ActivityImage url="/uploads/101_Screenshot_20221202_193020_Gallery_c60546cd38.jpg?updated_at=2023-01-29T19:23:54.807Z" alt="maze1" /> 
      <ActivityImage url="/uploads/100_Screenshot_20221202_192953_Gallery_84d88ffa78.jpg?updated_at=2023-01-29T19:23:54.695Z" alt="maze2" />
      <ActivityImage url="/uploads/99_Screenshot_20221202_193105_Gallery_ad78b25b97.jpg?updated_at=2023-01-29T19:23:54.609Z" alt="maze3" />
      <ActivityImage url="/uploads/98_Screenshot_20221202_193118_Gallery_14c3dbd9b2.jpg?updated_at=2023-01-29T19:23:54.353Z" alt="maze4" />
     
      </div>

      <p>Let them create a maze no matter the complexity. It will teach them patience and develop their ability to solve problems.</p>
      <ActivityImage url="/uploads/97_Screenshot_20221202_193005_Gallery_94c6321f22.jpg?updated_at=2023-01-29T19:23:54.331Z" alt="maze5" />
      <p>Have fun!</p>










      

    </div>
  )
}

export default CompleteaMaze
