import ActivityImage from '../../components/activity/activityImage'
import './water-squish-toys.css'


function WaterSquishToys() {
 
  return (
    <div >
      <p>Playing with these toys is great for kids because it helps them strengthen their hand muscles, which will help them with important skills like dressing and writing.</p>

<p>They also get to learn about cause-and-effect and have fun squirting water or other toys in the water. This activity can improve their focus and concentration too. Overall, it's a fun way for kids to develop important motor and cognitive skills.</p>

<p>Activities with water squirt toys:</p>

<ul>
  <li><b>Target Practice:</b> Set up a few empty cups, and have children try to squirt water into them. They can take turns or compete to see who can get the most shots in.</li>
  <li><b>Float and Squirt:</b> Place a few floating toys in a kiddie pool or bathtub, and challenge children to squirt them with their water toys.</li>
  <li><b>Fill a bucket: </b>Children can try to fill a bucket as fast as possible. They can even race each other to see who fills the bucket first!</li>
  </ul>



<p>Have fun! </p>


 









      

    </div>
  )
}

export default WaterSquishToys
