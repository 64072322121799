import ActivityImage from '../../components/activity/activityImage'
import './guess-the-drawing.css'


function GuesstheDrawing() {
 
  return (
    <div >
     <p>This game is simple, yet entertaining and can be played by kids of all ages.</p>

     <p><b>How to Play:</b></p>
     <ul>
<li>One player will draw something on another player's back using their finger or a pen-like object. </li>
<li>The other player will then have to guess what has been drawn either by guessing out loud or by drawing it on a piece of paper.</li>
 <li>If there are more than two players, they can stand in a line, and the person at the back will draw something on the back of the person in front of them.</li>
  The person in front will then carry what they think the picture is to the person in front of them, almost like the telephone game but just by drawing.
</ul>
  <p><b>Why it's Fun:</b></p>
This game is an excellent choice for children because it is simple and easy to play. 
It also encourages creativity and imagination. 
It's a fun way to develop communication skills and build teamwork, as players have to work together to guess what has been drawn. 
It's also a great way to develop sensory skills, as players have to rely on their sense of touch to guess what is being drawn on their back.


<p>Whether you're looking for a fun game to play on a rainy day or just want to add some excitement to your child's playtime, "Guess the Drawing" is a great choice. So grab some paper, pens, and get ready to have fun with your kids!</p>





      

    </div>
  )
}

export default GuesstheDrawing
