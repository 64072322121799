import ActivityImage from '../../components/activity/activityImage'
import './blow-bubbles.css'


function BlowBubbles() {
 
  return (
    <div >
      
      <p><b>Benefits of this activity</b></p>
      <ul>
        <li>Improving their hand-eye coordination</li>
        <li>Breath control</li>
        <li>Sensory development</li>
        <li>Providing relaxation and stress relief as it is fun</li>
      </ul>
      
      <p>Little children can spectate and pop the bubbles and older children can blow the bubbles themselves.</p>

      <p>If they use a bubble gun they can still develop their gross and fine motor skills by running after the bubbles and popping them.
           
          <p><b>Make your own bubble solution</b></p>
            <ul>
              <li>Use approximately 1 part dishwashing liquid and 5 parts water and mix the solution by stirring slightly.</li>
              <li>Glycerine can be added to make the bubbles last longer.</li>
              <li>The stick can be made by twisting a wire into a round shape with a handle.</li>
              </ul>  
      <ActivityImage url="/uploads/Screenshot_20221021_215556_300x288_a1bd6c71db.jpg?updated_at=2023-03-05T14:18:55.228Z" alt="blowbubbles1" /> 


            <p>If the bubbles pop before properly forming then dip the round part in dishwashing liquid. It will help the bubbles maintain their shape without popping immediately.</p>

   </p>










      

    </div>
  )
}

export default BlowBubbles
