import ActivityImage from '../../components/activity/activityImage'
import './straw-blowing-bubbles.css'


function StrawBlowingBubbles() {
 
  return (
    <div >
      <p>Fill a bowl with half water and half dishwashing liquid/bubblebath.</p>
      <p>Add a straw and start blowing bubbles!</p>
<div className='image-group'>
      <ActivityImage url="/uploads/Screenshot_20220822_153908_Gallery_300x288_ad7af7dfa2.webp?updated_at=2023-03-05T16:19:12.532Z" alt="strawbubbles1" /> 
      <ActivityImage url="/uploads/Screenshot_20221023_205948_300x297_fca13a1109.webp?updated_at=2023-03-05T16:19:16.513Z" alt="strawbubbles2" />
      <ActivityImage url="/uploads/Screenshot_20221023_210009_298x300_4a41eb5070.webp?updated_at=2023-03-05T16:19:20.114Z" alt="strawbubbles3" />
        </div>
      <p><b>Types of straws:</b> metal, paper, plastic or you can even make your own.</p>

    <p><b>How to make your own straw:</b></p>
      <ul>
        <li>Roll up a piece of paper.</li>
        <li>Put glue on the inner piece being rolled up or use sellotape and wrap it around the straw.</li>
      </ul>
      <ActivityImage url="/uploads/Screenshot_20221023_204846_300x300_287b95a4c5.jpg?updated_at=2023-03-05T16:19:03.921Z" alt="strawbubbles4" />
      <ActivityImage url="/uploads/Screenshot_20221023_204826_300x292_d3a6b55ef4.webp?updated_at=2023-03-05T16:19:07.842Z" alt="strawbubbles5" />

  <p><b>Little children can be taught the difference between blowing bubbles and drinking with the straw</b></p>
    <ul>
<li> Hold the straw close to their hand and blow on it and use the word "blow”.</li>
<li>You can also place a feather or sand on the table and blow that so that they can see the movement.</li>
<li>A cotton ball can be used to represent drinking. Make it stick to the straw by sucking and use the word "suck" or "drink".</li>

    </ul>
     
 <p>As soon as they have mastered the concepts and can blow sand or a feather then they can start doing the bubble acivity.</p>

 
 









      

    </div>
  )
}

export default StrawBlowingBubbles
