import ActivityImage from '../../components/activity/activityImage'
import './stamp-art.css'

function StampArt() {
 
  return (
    <div  >
     <p> They can make personalized wrapping paper, create something to put on the fridge or even decorate carton money boxes.</p>

     <p>Make your own stamps using household items like toilet paper rolls, potatoes, utensils, cookie cutters and sponges.</p>
     <div className='image-group'>
     <ActivityImage url="/uploads/106_Screenshot_20221130_201517_Gallery_3bb4aa451d.jpg?updated_at=2023-01-29T19:23:47.952Z" alt= "stampart1"/>
     <ActivityImage url="/uploads/105_Screenshot_20221127_141131_Gallery_134d569bc1.jpg?updated_at=2023-01-29T19:23:47.384Z" alt= "stampart2"/>
     <ActivityImage url="/uploads/104_Screenshot_20221127_141229_Gallery_cb6b5f5510.jpg?updated_at=2023-01-29T19:23:47.258Z" alt= "stampart3"/>
     <ActivityImage url="/uploads/95_20221203_164833_scaled_01beb80092.webp?updated_at=2023-01-29T19:23:56.555Z" alt= "stampart4"/>
     <ActivityImage url= "/uploads/103_Screenshot_20221127_141239_Gallery_272365f3ef.jpg?updated_at=2023-01-29T19:23:55.474Z" alt= "stampart5"/>
     </div>

      

    </div>
  )
}

export default StampArt