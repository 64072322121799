import ActivityImage from '../../components/activity/activityImage'
import './write-and-draw-a-story.css'


function WriteandDrawaStory() {
 
  return (
    <div >
      <p>Children will have the opportunity to be the author and illustrator of their own adventures. </p>
      <p>It can be done individually or as a group. The stories can be shared afterwards and bound together like a story book.</p>
      
      <div className='image-group'>
      <ActivityImage url="/uploads/374_Screenshot_20221028_204735_9acdc29712.webp?updated_at=2023-02-14T16:28:27.922Z" alt="story1" /> 
      <ActivityImage url="/uploads/372_Screenshot_20221028_204723_22d2a5a5a0.webp?updated_at=2023-02-14T16:28:27.280Z" alt="story2" />
      </div>
      
      <p><b>Story ideas</b></p>
      <ul>
      <li>The value of friendship.</li>
      <li>A story about the love and support of a family.</li>
      <li>Going on an adventure and overcoming challenges.</li>
      <li>A story about a character overcoming their fears.</li>
      <li>The power of kindness.</li>
      <li>Being responsible and taking care of oneself and others.</li>
      <li>A story about the power of imagination.</li>
      </ul>











      

    </div>
  )
}

export default WriteandDrawaStory
