import ActivityImage from '../../components/activity/activityImage'
import './what-is-in-the-box.css'


function WhatisintheBox() {
 
  return (
    <div >
     <ol>
  <li><b>Step 1:</b>Cut one side of the box open and make two holes on the sides.</li>
  <ActivityImage url="/uploads/Screenshot_20220917_201942_300x286_45d4749f7f.jpg?updated_at=2023-03-04T17:53:59.187Z" alt="whatisinthe box1" />
  <li><b>Step 2:</b> Add an item to the box.</li>
  <li><b>Step 3: </b>Let the person guessing the item stand behind the box so that they can insert their hands from the sides.</li>
  Items in the box can then be alternated. Children can take turns guessing.  
  <ActivityImage url="/uploads/Screenshot_20220917_202041_300x300_a44bb6be50.jpg?updated_at=2023-03-04T17:54:13.090Z" alt="whatisinthebox2" />
</ol>
<p>Different textures can be used to make the guessing more fun. The item can be something soft, spikey, jellylike, or even flexible.</p>











      

    </div>
  )
}

export default WhatisintheBox
