import ActivityImage from '../../components/activity/activityImage'
import './mixing-colors.css'


function MixingColors() {
 
  return (
    <div >
      <p>There are many ways to teach children how to mix different colors.</p>

      <p>They can learn about the color wheel. Primary colors like blue, yellow and red are used to mix the other colors. Secondary colors like purple, orange and green are the result of mixing primary colors.</p>
      
      <div className='image-group'>
      <ActivityImage url="/uploads/88_Screenshot_2022_12_10_202506_bde19c8007.jpg?updated_at=2023-01-29T19:23:53.299Z" alt="mixingcolors1" /> 
      <ActivityImage url="/uploads/87_Screenshot_2022_12_10_203047_0bfcc51ede.jpg?updated_at=2023-01-29T19:23:53.275Z" alt="mixingcolors2" />
      </div>

      <p><b>Mediums that can be used</b></p>
       <ul>
        <li>Oil paint on a paper.</li>
        <li>Clay.</li>
        <li>Food coloring.</li>
        <li>Face paint.</li>
        <li>Acrylic paint / water colors on a paper or in the bath as it is water soluble.</li>
        </ul>

       <p>Children can be taught that certain colors like gold and silver can not be obtained just by mixing primary and secondary colors. They are natural colors found in metallic form. Silver can be obtained by using grey and adding a gloss or metallic spray paint to it. Gold can be obtained by mixing yellow and brown or orange and brown pigments to create a gold-like color. Gloss or metallic spray paint can be added to make it look more shiny.</p>







      

    </div>
  )
}

export default MixingColors
