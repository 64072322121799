import ActivityImage from '../../components/activity/activityImage'
import './color-in-tote-bag.css'


function ColorinToteBag() {
 
  return (
    <div >
      <p>The bags can be bought from an Arts and Crafts store.</p>
      <p>Children can also draw their own pictures on the back.</p>
     
      <div className='image-group'>
      <ActivityImage url="/uploads/76_Screenshot_20221217_163345_Gallery_9383bcbd93.webp?updated_at=2023-01-29T19:23:52.669Z" alt="totebag1" /> 
      <ActivityImage url="/uploads/75_Screenshot_20221217_163336_Gallery_43f65f833f.webp?updated_at=2023-01-29T19:23:52.283Z" alt="totebag2" />
      
      </div>
      <p>Have fun!
      </p>











      

    </div>
  )
}

export default ColorinToteBag
