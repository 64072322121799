import ActivityImage from '../../components/activity/activityImage'
import './toilet-paper-mummy.css'


function ToiletPaperMummy() {
 
  return (
    <div >
     <p>
     Children can pretend that they lived during the time of the pharaohs. This fun activity that lets them create toilet paper mummies!</p>

<p>
  <ul>
    <li>Gather a few rolls of toilet paper.</li>
    <li>Take turns wrapping each other up in the toilet paper , just like the ancient Egyptians used to do with their deceased pharaohs.</li>
    <li>Once everyone is all fully wrapped up, create a mini photo shoot to show off the new creations to friends and family members.</li>
    </ul> </p>




<p>The fun doesn't have to stop there. Try making other types of clothes out of toilet paper like a dress or hat.The possibilities are endless!</p>

<p>Overall, making a toilet paper mummy is a fun and creative activity that lets children explore the world of ancient Egypt in a unique way. So go ahead, grab some toilet paper and start wrapping!

     </p>











      

    </div>
  )
}

export default ToiletPaperMummy
