import ActivityImage from '../../components/activity/activityImage'
import './your-dreams-and-goals.css'


function YourDreamsandGoals() {
 
  return (
    <div >
      
  <p>Encouraging children to think and talk about their dreams and goals can provide motivation and direction.</p>
  <p>We all have some things that we would still like to do in life whether it is a career path, a new skill we would like to learn, frienships that we want to build, games we want to play or places that we would like to see.</p>

  
  <li>Talking about their aspirations can help children become better at expressing themselves.</li>
  <li>Discussing their dreams and goals with others can provide valuable feedback and support.</li>
  <li>Overall, it can help children build a sense of purpose, develop their communication skills, and receive guidance and support.</li>

<p>Kids can create a poster as a reminder to pursue their aspirations, document their aspirations in a journal, or share their dreams with someone they choose, in order to stay motivated and inspired.</p>










      

    </div>
  )
}

export default YourDreamsandGoals
