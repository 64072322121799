import ActivityImage from '../../components/activity/activityImage'
import './play-telephone.css'


function PlayTelephone() {
 
  return (
    <div >
      <p>To play the game of telephone, participants stand or sit in a line and the first person whispers a word or sentence to the person on their left.</p>
      <p>They will then pass it on to the next person, and so on until the last person says out loud what they heard.</p>
      <p>It often has humorous results due to misunderstandings along the way, and then the game continues with the next person starting the next round.</p>











      

    </div>
  )
}

export default PlayTelephone
