import ActivityImage from '../../components/activity/activityImage'
import './abacus-games.css'


function AbacusGames() {
 
  return (
    <div >
     
     <h1>Games to play with an abacus</h1>
    <p>There are several games to play with an abacus. It can be used by children of any age. Babies and toddlers can just play around with it and develop their fine motor skills. Older children can use it for activities.</p>
    <h2>Benefits of playing with an abacus:</h2>
    <ul>
      <li>It can help with mental math.</li>
      <li>Improved hand-eye coordination.</li>
      <li>Increased concentration and focus.</li>
      <li>It helps with understanding spatial relationships.</li>
      <li>Improved memory.</li>
    </ul>
    <h2>Game #1 Basic math</h2>
    <p>Teach them how to do addition and subtraction.</p>
    <ActivityImage url="/uploads/348_Screenshot_20221030_103238_87ef9fb12c.webp?updated_at=2023-02-13T17:32:54.540Z" alt="abacus1" />
    <h2>Game #2 Create a pattern</h2>
    <div className='image-group'>
     <ActivityImage url="/uploads/347_Screenshot_20221030_103244_Gallery_a3a8307e92.webp?updated_at=2023-02-13T17:32:53.427Z" alt="abacus2" /> 
     <ActivityImage url="/uploads/346_Screenshot_20221030_103258_4e05205a2b.webp?updated_at=2023-02-13T17:32:54.750Z" alt="abacus3" />
     </div>
    <h2>Game #3 Create a picture</h2>
    <p>This is a good creative exercise that will also teach them to think and plan ahead as they need the right amount of blocks at a certain place to form the picture.</p>
    <ActivityImage url="/uploads/345_Screenshot_20221030_103307_697cafddd5.webp?updated_at=2023-02-13T17:32:54.494Z" alt="abacus4" />
    <h2>Game #4 Picture rub art</h2>
    <p>They can also create pictures by placing a paper over the blocks and rubbing over it with a crayon.</p>
    <ActivityImage url="/uploads/344_Screenshot_20221030_103333_5f519a74c7.webp?updated_at=2023-02-13T17:32:39.174Z" alt="abacus5" />
    <p>Have fun!</p>










      

    </div>
  )
}

export default AbacusGames
