import ActivityImage from '../../components/activity/activityImage'
import './build-a-pyramid.css'


function BuildaPyramid() {
 
  return (
    <div >
      <p>Children can build large pyramids as long as you have cards to spare.</p>
      <p>It is recommended that older cards are used as their edges are a bit thicker (worn) which increases its grip.</p>
      <p><b>Step 1:</b> Use two cards at a time and place them against each other on the ground with the base slightly apart, forming a triangle.</p>
     
      <ActivityImage url="/uploads/Screenshot_20221106_153626_292x300_017291e067.jpg?updated_at=2023-03-05T15:21:29.401Z" alt="cardpyramid1" /> 
        <p><b>Step 2:</b> Repeat the process with 2 other cards, one of their bases next to the first pair.</p>
     <p><b>Step 3: </b>Place the platform card on top.</p>
     <p><b>Step 4:</b> Place the next pair on top of the platform card</p>
      <ActivityImage url="/uploads/Screenshot_20221106_154111_286x300_f4c520b28e.jpg?updated_at=2023-03-05T15:21:32.435Z" alt="cardpyramid2" />
   
   <p>Expand!</p> 
<p>If you do not have playing cards,  you can cut out rectangular pieces of carton from cereal boxes and stack them.</p>
      

























      

    </div>
  )
}

export default BuildaPyramid
