import ActivityImage from '../../components/activity/activityImage'
import './draw-or-write-with-both-hands.css'


function DraworWritewithBothHands() {
 
  return (
    <div >
      <p>Let's try a fun and challenging activity!</p>
      <p> Grab two pens and put one in each hand. Now, try to draw a symmetrical picture using both hands at the same time. Whatever you draw with your dominant hand must be mirrored on the page with your other hand.</p>

<p>To make things even more exciting, try drawing or writing with your non-dominant hand. You can either draw something beforehand with your dominant hand to use as a reference, or just let your creativity flow and see what comes out. This activity is a great way to step out of our comfort zones and challenge ourselves.</p>


     
      <ActivityImage url="/uploads/Screenshot_20221030_185415_300x201_4a69dec32b.jpg?updated_at=2023-03-21T16:15:24.348Z" alt="writeanddrawwithbothhands" /> 
     
   











      

    </div>
  )
}

export default DraworWritewithBothHands
