
import ActivityImage from '../../components/activity/activityImage'
import './paint-pour-art.css'

function PaintPourArt() {
 
  return (
    <div  >
      <p>Let them wear old clothes, aprons or black refuse bags as this is a messy activity!</p>
      <p>Pour the paint into a container and mix it with water 2:1 ratio or until it is runny enough to easily pour onto the paper.</p>
      <ActivityImage url="/uploads/42_Screenshot_20230109_214057_Gallery_2143c496a3.jpg?updated_at=2023-01-29T19:24:06.356Z" alt= "paintpour1"/>

      <p>Let them pour the paint on the paper or canvas. They can use utensils to create other pictures or spread the paint on the paper.</p>
      <div className='image-group'>
      <ActivityImage url= "/uploads/38_Screenshot_20230109_221039_Gallery_679cfbc47d.webp?updated_at=2023-01-29T19:24:05.165Z" alt= "paintpour2"/>
      <ActivityImage url= "/uploads/40_Screenshot_20230109_214125_Gallery_95b1d0c0d5.webp?updated_at=2023-01-29T19:24:05.538Z" alt= "paintpour3"/>
      </div>
      <p>Tip the canvas slightly to help spread the paint or create new designs.</p>

      <p>A hairdryer can be used with assistance/under supervision to create web like pictures or just to mix the colors.</p>

      <p><b>Tip: </b> Paint can crack if the top dries faster than the bottom. Use the cool option of the hairdryer to spread the paint over the canvas without drying the top too fast. The picture can be left out in the sun to dry. Have fun!
      </p>

      

    </div>
  )
}

export default PaintPourArt
