import ActivityImage from '../../components/activity/activityImage'
import './treasure-hunt-with-a-map.css'


function TreasureHuntwithaMap() {
 
  return (
    <div >
      <p>Treasure hunts are a great way for kids to develop problem-solving and critical thinking skills, as well as enhance their communication and teamwork abilities, all while having fun.</p>
      <p>Draw a treasure map of an area within or outside the house, consisting of either a single or multiple destinations, where clues to the next destination are provided or all information is present on a single map, allowing them to follow the instructions and ultimately discover the treasure; this activity can be enjoyed by all and even little children can learn to draw a map just for fun.</p>
      
    
      <ActivityImage url="/uploads/Screenshot_20221101_230152_768x589_e8cc84738e.webp?updated_at=2023-03-04T18:29:49.673Z" alt="treasurehunt" /> 
      
      











      
      <p>Let the hunt commence!</p>
    </div>
  )
}

export default TreasureHuntwithaMap
