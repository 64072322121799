import ActivityImage from '../../components/activity/activityImage'
import './tenpin-bowling.css'


function TenpinBowling() {
 
  return (
    <div >
     <p>
     Tenpin Bowling is a fun game that can be enjoyed by kids of all ages. It can help children develop important skills like hand-eye coordination, balance, and concentration.</p>

<p><b>To play,</b> all you need is a ball and some pins. The size of the ball can vary from a ping pong ball to a soccer ball, depending on the age and skill level of the players. Create your own bowling alley using items you have at home.</p>

<p><b>For the pins,</b> anything with a rectangular shape that can be knocked over. Some ideas include plastic bottles, pins made from paper, empty long containers, lego towers, or thick crayons for mini ten pin bowling. Be creative and use whatever you have on hand to create your own unique set of pins.</p>

<p><b>Start playing!</b> Set up your pins at one end of your "alley" and take turns rolling the ball towards them. The goal is to knock down as many pins as possible with each roll.</p>
<ActivityImage url="/uploads/Screenshot_20221023_213140_768x739_3305ece8ea.webp?updated_at=2023-03-21T16:05:48.879Z" alt="tenpinbowling" />


<p></p>Tenpin Bowling is a great way to get some exercise and spend time with friends and family, while also developing important skills that can be used both on and off the bowling alley. So grab a ball and some pins, create your own bowling alley, and get ready to have a blast playing Tenpin Bowling!


     











      

    </div>
  )
}

export default TenpinBowling
