import ActivityImage from '../../components/activity/activityImage'
import './limbo.css'


function Limbo() {
 
  return (
    <div >
      <p>In limbo, players take turns to pass under a lowered bar without touching it or falling over, with the last player to do so being the winner.</p>
      
      <p><b>Benefits of this activity for small children</b></p>
      <p>Playing limbo can benefit babies by improving their motor skills, coordination, balance, sensory awareness, social interaction, and cognitive development.</p>
      <p><b>Note: </b>babies should only play under adult supervision and with appropriate equipment, such as a soft foam bar or a scarf, to ensure their safety.</p>

      <ActivityImage url="/uploads/311_Screenshot_20221103_200206_8b7e2b0811.webp?updated_at=2023-01-29T19:23:24.074Z" alt="limbo1" /> 

      <p><b>Tips for older children:</b></p>
      <ul>
         <li>Keep your back straight and bend from the hips, not the waist.</li>
         <li>Move your arms and legs in unison to maintain balance.</li>
         <li>Take small, slow steps to avoid losing balance.</li>
         <li>Focus on the bar and your footing to avoid distractions.</li>
      </ul>
      

       
      
    





      

    </div>
  )
}

export default Limbo
