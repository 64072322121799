import ActivityImage from '../../components/activity/activityImage'
import './arts-and-crafts.css'


function ArtsandCrafts() {
 
  return (
    <div >
      <p>The possibilities are endless!</p>
      
      <p> They can use different types of crayons, pencils or pens. Put some glue on the paper and add a few pom poms, beads, glitter , gemstones and wiggly eyes.</p>
      <div className='image-group'>
      <ActivityImage url="/uploads/861_Screenshot_20220905_185556_Gallery_7d728826bd.jpg?updated_at=2023-02-13T17:17:52.042Z" alt="artsandcrafts1" /> 
      <ActivityImage url="/uploads/852_Screenshot_20220906_160025_Gallery_fda88646d6.jpg?updated_at=2023-02-13T17:21:44.742Z" alt="artsandcrafts2" />
      <ActivityImage url="/uploads/824_Screenshot_20220906_204405_Gallery_a2ec2b550f.jpg?updated_at=2023-02-13T17:18:06.380Z" alt="artsandcrafts3" />
     
      </div>
      <p>Have fun!
      </p>











      

    </div>
  )
}

export default ArtsandCrafts
