import ActivityImage from '../../components/activity/activityImage'
import './accordion-paper-folding.css'


function AccordionPaperFolding() {
 
  return (
    <div >
      <p><b>Applications of accordion paper folding:</b></p>
      <ul>
        <li>Pop-up Cards: Fold an accordion-style strip of paper and glue it onto a piece of cardstock to create a pop-up card.</li>
        <li>Origami Figures: Fold multiple accordion-style strips of paper to create the body, legs, and arms of origami animals or people.</li>
        <li>Scrapbook Embellishments: Fold strips of patterned paper into accordions, then use them to decorate scrapbook pages, photo albums, or other craft projects.</li>
        <li>Paper Flowers: Fold multiple strips of paper into accordions, then twist and shape them into paper flowers to create a bouquet or a single flower.</li>
        <li>Wall Art: Fold multiple strips of paper into accordions, then arrange them in a pattern or design and use them as a piece of wall art.</li>
      </ul>

      <p><b>Step 1: Take a long strip of paper and fold the one half over.</b></p>
      <div className='image-group'>
      <ActivityImage url="/uploads/Screenshot_20230208_211945_Gallery_0240eafa43.jpg?updated_at=2023-02-08T19:23:08.121Z" alt="accordionpaper1" /> 
      <ActivityImage url="/uploads/Screenshot_20230208_211959_Gallery_967ba2eba7.jpg?updated_at=2023-02-08T19:23:16.591Z" alt="accordionpaper2" />
      </div>
      <p><b>Step 2: Fold the bottom strip over the top one.</b></p>
      <ActivityImage url="/uploads/Screenshot_20230208_212012_Gallery_171120fe41.jpg?updated_at=2023-02-08T19:23:20.091Z" alt="accordionpaper3" />
      <p>Step 3: Repeat the pattern of folding the bottom strip over the top one until the whole strip have been used.</p>
      
      <ActivityImage url="/uploads/Screenshot_20230208_212025_Gallery_9214baebb2.jpg?updated_at=2023-02-08T19:23:24.670Z" alt="accordionpaper4" />
      
      <p>Create new items</p>
      <div className='image-group'>
      
      <ActivityImage url="/uploads/202_Screenshot_20221112_183422_3d5eaad816.jpg?updated_at=2023-01-29T19:23:42.496Z" alt="accordionpaper6" />
      <ActivityImage url="/uploads/201_Screenshot_20221112_183433_66a2685253.jpg?updated_at=2023-01-29T19:23:42.132Z" alt="accordionpaper7" />
      <ActivityImage url="/uploads/199_Screenshot_20221112_185324_a4af62f49e.jpg?updated_at=2023-01-29T19:23:42.153Z" alt="accordionpaper8" />
      </div>
      
      <p>Have fun!</p>











      

    </div>
  )
}

export default AccordionPaperFolding
