import { useContext, useEffect, useState } from "react";
import Activity from "../../components/activity/Activity";
import "./home.css";

import MeiliSearch from "meilisearch";
import { useRef, useCallback } from "react";
import { uniqBy } from "lodash";
import { FilterContext } from "../../App";


function Home({ searchValue }) {
  const {filter, setFilter} = useContext(FilterContext)
  const {selectedTypes, selectedAges, selectedAreas} = filter
  const [Activities, setActivities] = useState([]);

  const observerElem = useRef(null);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [offset, setOffset] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [searchQuery, setSearchQuery] = useState(['*']);

  const fetchData = async (searchStr = "") => {
    let currentOffset = offset;
    const client = new MeiliSearch({
      host: "https://api.artsandparenting.com/meilli", //"https://ms-53118596bd8e-1995.lon.meilisearch.io",
      apiKey: "996c07ef10385217a247b3577edd2013534b742a"
    });

    const index = await client.getIndex("activity");
    if (searchStr === "") {
      searchStr = "*";
    }
    const searchQ = [];
    const selectedTypeNames = selectedTypes.map((type) => type.name )
    if(selectedTypeNames.length > 0){
      searchQ.push(`activity_types.name = '${selectedTypeNames.join("' OR activity_types.name = '")}'`)
    }
    const selectedAgeNames = selectedAges.map((age) => age.name )
    if(selectedAgeNames.length > 0){
      searchQ.push(`ages.range = '${selectedAgeNames.join("' OR ages.range = '")}'`)
    }
    const selectedAreaNames = selectedAreas.map((area) => area.name )
    if(selectedAreaNames.length > 0){
      searchQ.push(`areas.name = '${selectedAreaNames.join("' OR areas.name = '")}'`)
    }

    const searchQCompare = [...searchQ].concat([searchStr])
    if(searchQuery.join('') !== searchQCompare.join('')){
      setOffset(0)
      currentOffset = 0
    }
    try{
      const ActivitiesData = await index.search(searchStr, {
        limit: 15,
        offset: currentOffset,
        filter: searchQ
      });
      searchQ.push(searchStr)
      setTotalEntries(ActivitiesData.estimatedTotalHits);

      if(searchQuery.join('') === searchQ.join('')){
        const newActivities = uniqBy([...Activities, ...ActivitiesData.hits], 'id')
        setActivities(newActivities)
      } else {
        const newActivities =  uniqBy([...ActivitiesData.hits], 'id')
        setActivities(newActivities)
      }
      setSearchQuery(searchQ)
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
      fetchData(searchValue);
  }, [searchValue]);

  useEffect(() => {
    fetchData(searchValue);
  }, [filter]);


  useEffect(() => {
    setOffset(Activities.length);

    if (Activities.length < totalEntries) {
      console.log("has next page");
      setHasNextPage(true);
    } else {
      console.log("no next page");
      setHasNextPage(false);
    }
  }, [Activities]);

  const handleObserver = useCallback(
    (entries) => {
      const [target] = entries;
      if (target.isIntersecting && hasNextPage) {
        fetchData(searchValue);
      }
    },
    [fetchData, hasNextPage]
  );

  useEffect(() => {
    const element = observerElem.current;
    const option = { threshold: 0 };
    const observer = new IntersectionObserver(handleObserver, option);
    observer.observe(element);
    return () => observer.unobserve(element);
  }, [hasNextPage, handleObserver]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="home">
      <div className="allActivities wrapper">
        <h2>Browse Activities</h2>
        <div className="Activities">
          {Activities?.map((activity) => (
            <Activity
              key={activity.id}
              title={activity.title}
              subtitle={activity.subtitle}
              cover={activity.cover}
              type={activity.activity_types}
              ages={activity.ages}
              id={activity.id}
              filename={activity.filename}
            />
          ))}
        </div>

        <div className="loader" ref={observerElem}>
          {Activities.length !== 0 && (
            <span>{hasNextPage ? "Loading..." : "no Activities left"}</span>
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;
