import './activityImage.css'


function ActivityImage({url, alt = 'activity'}) {
  return (
    <div className="activity-image">
      <img src={'https://api.artsandparenting.com' + url} alt={alt} />
    </div>
  );
}
export default ActivityImage;