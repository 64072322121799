import ActivityImage from '../../components/activity/activityImage'
import './scrape-painting.css'


function ScrapePainting() {
 
  return (
    <div >
      <p>Simply add a few drops of paint to the paper in any pattern and scrape over it with a flat object that is easy enough to clean.</p>
      <p>You can use a window cleaner, spatulas, rulers or disposable items like carton.</p>
   
      <div className='image-group'>
      <ActivityImage url="/uploads/222_Screenshot_20221110_124004_568e1e76cc.jpg?updated_at=2023-01-29T19:23:37.404Z" alt="scrapepaint1" /> 
      <ActivityImage url="/uploads/223_Screenshot_20221110_123943_Gallery_4e958e658a.webp?updated_at=2023-01-29T19:23:37.500Z" alt="scrapepaint2" />
      
      </div>
   
      <p>The scraper with the excess paint can be used afterwards to create other pictures.</p>
      <ActivityImage url="/uploads/221_Screenshot_20221110_131841_7d0f2ba86f.webp?updated_at=2023-01-29T19:23:37.659Z" alt="scrapepaint3" />
      <p>Have fun!</p>









      

    </div>
  )
}

export default ScrapePainting
