import ActivityImage from '../../components/activity/activityImage'
import './bean-bag-activities.css'


function BeanBagActivities() {
 
  return (
    <div >
      
      
    <h1>Bean bag activities</h1>
    <p>This is a fun way for children to develop their gross motor skills.</p>
    <h2>Different activities</h2>
    <ol>
      <li>
        <h3>Bean bag toss</h3>
        <p>Throw one bean bag to other people. Try and catch it with different hands or increase the distance between players once a bean bag has been caught. Give each person a bean bag and let them throw it at the same time and see whether they can catch the other bean bags simultaneously.</p>
      </li>
      <li>
        <h3>Throw bean bags through a hoop</h3>
        <p> they can also place a hoop on the ground and let them throw it in the circle.</p>
      </li>
      <li>
        <h3>Balance the bean bag</h3>
        <p>on your head, knees or arms while doing different things like sitting, walking or twirling.</p>
      </li>
      <li>
        <h3>Throw the bean bag into a bucket</h3>
        <p>Use the other hand as well to throw it with.</p>
      </li>
      <li>
        <h3>Throw the bean bag in the air</h3>
        <p>and try to catch it behind your back.</p>
      </li>
      <li>
        <h3>Learn to juggle with the bean bags</h3>
      </li>
    </ol>









      

    </div>
  )
}

export default BeanBagActivities
