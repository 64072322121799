import ActivityImage from '../../components/activity/activityImage'
import './activities-with-a-large-box.css'


function ActivitieswithaLargeBox() {
 
  return (
    <div >
    
    <h2>Activity #1: Create a TV</h2>
    <p>It's time for some animation! Cut out one side of a large box and add a cardboard cutout as a stage. Decorate the television however you like and add your content. The children can even be on the television themselves! Let them put the box over their heads with their eyes peeking through the cutout and let the animation begin. Take turns making up different stories for even more fun.</p>
    <ActivityImage url="/uploads/Screenshot_20220917_160931_768x799_3fe163dd50.jpg?updated_at=2023-03-05T18:31:04.971Z" alt="largebox1" />
    <h2>Activity #2: Make a Vehicle</h2>
    <p>Transform your large box into a car, bus, train, or plane. Draw wheels and windows on the outer side, then cut out wheels from paper and attach them to the box. Add a chair rest or pillow and a steering wheel for even more imagination.</p>
    <ActivityImage url="/uploads/Screenshot_20220917_160501_300x236_6c04f96ac4.jpg?updated_at=2023-03-05T18:31:09.966Z" alt="largebox2" />
    <h2>Activity #3: Create a Tunnel</h2>
    <p>If the box is big enough and open at both ends, it can be used as a tunnel for crawling. Babies can practice crawling while bigger kids can learn to leopard crawl. Children can also just lie in the tunnel for some peaceful quiet time.</p>
    <ActivityImage url="/uploads/Screenshot_20220917_130100_292x300_50b31ab8a0.jpg?updated_at=2023-03-05T18:31:14.254Z" alt="largebox3" />
    <h2>Activity #4: Build a Tent</h2>
    <p>Transform your box into a cozy tent! Simply drape a blanket over the top and let the kids climb inside. Give them a flashlight and some toys, books, or snacks for their own little adventure.</p>
    <h2>Activity #5: Draw and Color</h2>
    <p>Use your large box as a fun and creative space for drawing and coloring. Children can sit inside and use a few crayons to draw whatever they like. They can scribble anywhere they want, making it the perfect place to teach little ones how to draw.</p>
    <h2>Activity #6: Design a Maze</h2>
    <p>With a large box, you can create a fun maze for kids to explore. Cut out holes and passages in different parts of the box, then let the children crawl through and explore. You can even time them to see who can make it through the fastest!</p>
    <h2>Activity #7: Build a Playhouse</h2>
    <p>Your large box can also be turned into a playhouse! Cut out windows and a door, then decorate it with markers or paint. Add some pillows or blankets inside for a cozy space for kids to play and let their imaginations run wild.</p>



      
      
      <p>Have fun!
      </p>











      

    </div>
  )
}

export default ActivitieswithaLargeBox
