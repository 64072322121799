import ActivityImage from '../../components/activity/activityImage'
import './roast-marshmallows.css'


function RoastMarshmallows() {
 
  return (
    <div >
      <p>Use a candle and skewer sticks indoors or a stick and campfire outdoors.</p>
     
      <p>The marshmallows can be eaten layer by layer, placed between biscuits or dipped in milk/cream/chocolate.</p>

      <div className='image-group'>
      <ActivityImage url="/uploads/339_Screenshot_20221030_095259_963db37d9f.jpg?updated_at=2023-01-29T19:23:17.926Z" alt="roastmarshmallow1" /> 
      <ActivityImage url="/uploads/351_Screenshot_20221030_095320_6855630452.jpg?updated_at=2023-01-29T19:23:20.443Z" alt="roastmarshmallow2" />
      <ActivityImage url="/uploads/350_Screenshot_20221030_095329_cd19108b17.webp?updated_at=2023-01-29T19:23:20.678Z" alt="roastmarshmallow3" />
      
      </div>
    
 <p>Supervision will be required as the children will be around fire.</p>










      

    </div>
  )
}

export default RoastMarshmallows
