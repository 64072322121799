import ActivityImage from '../../components/activity/activityImage'
import './learn-about-countries.css'


function LearnAboutCountries() {
 
  return (
    <div >
      <p>There are currently 195 recognized countries in the world.</p>

      <p>There are several ways to help kids learn about countries, such as 
        <ul>
          <li>purchasing books with interesting information</li>
          <li>using a globe to point out where different countries are located</li>
          <li>searching for fun facts on Google</li>
          <li>you can also make use of Google Street View to virtually explore different places around the world with your children.</li>
          </ul>  </p>

    <p>Here are a few interesting topics that children can learn about when exploring different countries:</p>
    

<ol>
  <li><b>Geography</b> - children can learn about different landforms, climate, and natural features such as mountains, deserts, rivers, and oceans.</li>
  <li><b>Culture</b> - children can learn about the traditions, customs, music, and festivals of different countries.</li>
  <li><b>Food</b> - children can learn about popular dishes, ingredients, and cooking methods from different regions of the world.</li>
  <li><b>Language</b> - children can learn some basic phrases and greetings in different languages, which can be a fun way to communicate with people from other cultures.</li>
  <li><b>History</b> - children can learn about significant events, important historical figures, and cultural heritage of different countries.</li>
  <li><b>Wildlife</b> - children can learn about the unique animal species found in different countries and their habitats.</li>
  <li><b>Sports</b> - children can learn about popular sports and games played in different countries, and how they reflect their culture and traditions.</li>
  <li><b>Art</b> - children can learn about the art styles and famous artists of different countries, and create their own artwork inspired by them.</li>
</ol>

<p>Interesting facts about countries:</p>
<ul>
<li>The largest country in the world by land area is Russia, covering over 17 million square kilometers.</li>
<li>The smallest country in the world by land area is Vatican City, with an area of just 0.44 square kilometers.</li>
<li>The national animal of Scotland is the unicorn.</li>
<li>In Japan, it is considered polite to slurp your noodles while eating.</li>
<li>Mexico is the birthplace of chocolate, which was originally consumed as a bitter drink.</li>
<li>Finland has the most islands of any country in the world, with over 180,000.</li>
<li>The world's largest flower, the Rafflesia arnoldii, can be found in Indonesia and can grow up to three feet in diameter.</li>
<li>Australia is home to the world's largest coral reef system, the Great Barrier Reef.</li>
<li>There are over 1,000 different languages spoken in Africa.</li>

</ul>














      

    </div>
  )
}

export default LearnAboutCountries
