import ActivityImage from '../../components/activity/activityImage'
import './paper-family.css'


function PaperFamily() {
 
  return (
    <div >
      <p> <b>Step 1: Fold a zig zag. It is done the same way as a paper fan.</b></p>  
      <ActivityImage url="/uploads/Screenshot_20230208_182354_Gallery_a5c81619c9.webp?updated_at=2023-02-08T16:32:59.694Z" alt="paperfamily1" /> 
      <p><b>Step 2: Draw the picture on one side of the paper with one or more lines touching both sides.</b></p>
      <ActivityImage url="/uploads/Screenshot_20230208_182417_Gallery_b7c2f7a148.webp?updated_at=2023-02-08T16:33:11.064Z" alt="paperfamily2" />
      <p><b>Step 3: Cut out the picture. Leave at least one area on both sides that does not get cut.</b></p>
      <ActivityImage url="/uploads/Screenshot_20230208_182432_Gallery_d562376744.webp?updated_at=2023-02-08T16:33:15.557Z" alt="paperfamily3" />
      <p>The pink area will be cut and the blue area </p>
      <ActivityImage url="/uploads/Screenshot_20230208_182451_Gallery_8f75dd94c0.webp?updated_at=2023-02-08T16:33:19.626Z" alt="paperfamily4" />
      <p><b>Step 4: Fold open. They can draw some faces on.</b></p>
      <ActivityImage url="/uploads/Screenshot_20230208_182513_Gallery_0fd94d2ced.webp?updated_at=2023-02-08T16:33:07.169Z" alt="paperfamily5" />
      
      <p>Have fun!</p>

    
      
      

      

    </div>
  )
}

export default PaperFamily
