import ActivityImage from '../../components/activity/activityImage'
import './felt-puzzle.css'


function FeltPuzzle() {
 
  return (
    <div >
      <p>The kits can be bought from Arts and Crafts stores.</p>
      <ActivityImage url= '/uploads/49_Screenshot_20221231_191548_Gallery_baaacd5007.webp?updated_at=2023-01-29T19:23:56.812Z' alt= 'feltpuzzle3'/>
      <p>Three step process:</p>
      <p><b>Step 1: </b> Separate the backing paper from the felt shape that you want to use. Start by using the larger felt shapes then move on to the smaller pieces.</p>
      <div className='image-group'>
       <ActivityImage url="/uploads/48_Screenshot_20221231_191538_Gallery_efc34fa160.webp?updated_at=2023-01-29T19:23:55.925Z" alt = "feltpuzzle1"/>
       <ActivityImage url= '/uploads/47_Screenshot_20221231_191530_Gallery_7ed3a1a33a.webp?updated_at=2023-01-29T19:23:56.279Z' alt= "feltpuzzle2"/>
       <ActivityImage url= "/uploads/46_Screenshot_20221231_191522_Gallery_8826b730f0.webp?updated_at=2023-01-29T19:23:55.893Z" alt= "feltpuzzle4"/>
      </div>
      <p><b>Step 2: </b> Carefully place the felt shape in the correct place by following the picture on the packaging.</p>
      <ActivityImage url= "/uploads/45_Screenshot_20221231_191514_Gallery_45468ed7fc.webp?updated_at=2023-01-29T19:24:06.329Z" alt= "feltpuzzle5"/>
      <p><b>Step 3: </b>Repeat the process until the felt puzzle is complete.</p>
      <ActivityImage url= "/uploads/43_Screenshot_20221231_191505_Gallery_1_ae927cad85.webp?updated_at=2023-01-29T19:24:06.066Z" alt= "feltpuzzle6"/>
     

    </div>
  )
}

export default FeltPuzzle

