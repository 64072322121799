import ActivityImage from '../../components/activity/activityImage'
import './have-a-tea-party.css'


function HaveaTeaParty() {
 
  return (
    <div >
     <p>Children can get involved in the planning and preparation of the tea party.They can set the table, choose the menu, and make invitations for their guests (even if it is a soft toy)</p>

<p>To host a tea party, you'll need a few key items, such as a teapot, tea cups, saucers, and plates. You can also add a tablecloth, fresh flowers, and snacks like cookies or sandwiches.</p>
<p>During the tea party, children can practice their manners and etiquette, such as pouring tea for their guests, using utensils properly, and engaging in polite conversation.</p>
<p>Hosting a tea party is a fun and memorable activity for children that can help foster their creativity, social skills, and self-confidence, all while having a great time with friends and family.</p>












      

    </div>
  )
}

export default HaveaTeaParty
