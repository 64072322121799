import ActivityImage from '../../components/activity/activityImage'
import './water-baby-decorations.css'


function WaterBabyDecorations() {
 
  return (
    <div >
      <p><b>Method</b></p>
      <ul>
      <li>Add the saturated water babies and decorations to a see through jar.</li>
      <li>Fill the jar with water and watch how the water babies blend with the water.</li>
      <li>Seal the jar.</li>
      </ul>

      <div className='image-group'>
      <ActivityImage url="/uploads/93_Screenshot_20221204_204253_Gallery_488b2f5d98.jpg?updated_at=2023-01-29T19:23:54.225Z" alt="waterbabies1" /> 
      <ActivityImage url="/uploads/92_Screenshot_20221204_204241_Gallery_c4842093ac.jpg?updated_at=2023-01-29T19:23:54.003Z" alt="waterbabies2" />
      <ActivityImage url="/uploads/91_Screenshot_20221204_204225_Gallery_4684b8ffcf.jpg?updated_at=2023-01-29T19:23:54.041Z" alt="waterbabies3" />
      <ActivityImage url="/uploads/89_Screenshot_20221204_204204_Gallery_1_4546900929.jpg?updated_at=2023-01-29T19:23:53.839Z" alt="waterbabies4" />
      </div>
      <p>The colored water babies can also be used on their own to make rainbow coloured decorations.</p>

      <p><b>Note </b> : This activity requires some supervision to ensure that the water babies are not swallowed by children or flushed down the drain</p>

      

    </div>
  )
}

export default WaterBabyDecorations
