import ActivityImage from '../../components/activity/activityImage'
import './colander-fine-motor-activity.css'


function ColanderFineMotorActivity() {
 
  return (
    <div >
      <p>This activity can also increase concentration and teach them patience.</p>

      <p>A colander or egg carton can be used with pasta or chenille sticks.</p>
      
      <div className='image-group'>
      <ActivityImage url="/uploads/192_Screenshot_20221113_162723_e077112572.webp?updated_at=2023-01-29T19:23:41.521Z" alt="colander1" /> 
      <ActivityImage url="/uploads/191_Screenshot_20221113_164437_1_84f2f3b2f6.webp?updated_at=2023-01-29T19:23:41.588Z" alt="colander2" />
    
      </div>
      <p>Babies that are not able to stick the chenille into the holes can just play with the sticks. Teach them about different textures and things that bend. You can show them how the activity works until they are able to do it themselves.
      </p>











      

    </div>
  )
}

export default ColanderFineMotorActivity
