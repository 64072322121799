import ActivityImage from '../../components/activity/activityImage'
import './omo.css'


function OMO() {
 
  return (
    <div >
      <p>This one can go on for as long as you have space left on the paper. A game for two players and up.</p>
      <p>Use a paper with a grid or draw your own blocks.</p>
   <ActivityImage url="/uploads/743_Screenshot_20220923_075513_2e786d5ca7.jpg?updated_at=2023-02-14T16:11:21.129Z" alt="omo1" />
     <p>Take turns writing the letter “O” or “M” in any of the blocks. If you write a letter and see the word “OMO”, then strike it through. That person gets to write the next letter.</p>
      <div className='image-group'>
       
      <ActivityImage url="/uploads/742_Screenshot_20220923_075523_13547f547b.jpg?updated_at=2023-02-14T16:11:20.724Z" alt="omo2" />
      <ActivityImage url="/uploads/741_Screenshot_20220923_075534_6a4167f7da.jpg?updated_at=2023-02-14T16:11:46.071Z" alt="omo3" />
      </div>
      
       <p> A letter that has a strike through may be used to build the next OMO word. The person with the most strikethroughs win. 
      </p>


      













      

    </div>
  )
}

export default OMO
