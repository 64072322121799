import ActivityImage from '../../components/activity/activityImage'
import './chores-for-pocket-money.css'


function ChoresforPocketMoney() {
 
  return (
    <div >
      <p>It's never too early to start instilling a sense of responsibility in children. Doing chores can be a great way to teach them valuable life skills such as work ethic and time management.</p>

<p>By doing some chores together with your<b> little ones,</b> they can learn by example and also enjoy spending time with you. It's a perfect opportunity to teach them how to use basic cleaning tools like a mop and broom, as well as how to wash dishes and do laundry. Even simple tasks like putting clothes in the washing machine and adding detergent can give them a sense of accomplishment and trust.</p>

<p><b>As children get older</b> and start showing interest in buying things for themselves, they can be given the opportunity to earn pocket money by doing chores. This can teach them to appreciate the value of hard work and money. Encourage them to save their earnings by providing a piggy bank or helping them make one at home. Chores can be a fun and rewarding way for children to develop important life skills while also contributing to the family's daily routine.

<ActivityImage url="/uploads/Screenshot_20221113_190903_768x863_bef3039256.webp?updated_at=2023-03-21T16:37:04.305Z" alt="choresforpocketmoney" />
<p><b>Here are some age-appropriate chores for children:</b></p>

<p><b>Ages 2-3:</b></p>
<ul>
  <li>Helping to put away toys</li>
  <li>Assisting with simple tasks such as picking up trash </li>
  <li>Assisting with setting the table</li>
  <li>Helping to feed pets</li>
  <li>Helping to water plants</li>
</ul>

<p><b>Ages 4-5:</b></p>
<ul>
  <li>Putting away toys</li>
  <li>Helping to fold laundry</li>
  <li>Helping to clear the table after meals</li>
  <li>Dusting furniture</li>
  <li>Helping to clean up spills</li>
  </ul>


<p><b>Ages 6-7:</b></p>
<ul>
  <li>Doing simple laundry tasks, such as sorting clothes or matching socks</li>
  <li>Setting the table for meals</li>
  <li>Helping to prepare simple meals, such as sandwiches or salads</li>
  <li>Helping to sweep floors</li>
  <li>Helping to water plants</li>
</ul>
<p><b>Ages 8-9:</b></p>
<ul>
  <li>Doing simple laundry tasks, such as loading and unloading the washing machine or dryer</li>
  <li>Helping to prepare more complex meals, such as boiling pasta or baking cookies</li>
  <li>Helping to sweep and mop floors</li>
  <li>Helping to dust and clean furniture</li>
  <li>Helping to clean bathrooms</li>
</ul>
<p><b>Ages 10-12:</b></p>
<ul>
<li>Doing laundry, including sorting, washing, drying, and folding clothes</li>
<li>Helping to prepare more complex meals, such as using the stove or oven</li>
<li>Helping to clean the kitchen, including washing dishes and wiping counters</li>
<li>Helping to clean the bathroom, including scrubbing the tub and toilet</li>
<li>Helping to sweep and mop floors</li>
</ul>
<p>It is important to assign age-appropriate chores to children to ensure that they are able to safely and effectively complete the tasks. Remember to always supervise children while they are completing chores to ensure their safety.

</p>
      </p>











      

    </div>
  )
}

export default ChoresforPocketMoney
