import ActivityImage from '../../components/activity/activityImage'
import './where-is-wally.css'


function WhereisWally() {
 
  return (
    <div >
      <p>These books can keep children entertained for long.</p>

      <p>The objective is to find Wally on every themed double page. Everyone can play together and help each other find him. The back of the book contains a list of other items to look out for.</p>
      
      <div className='image-group'>
      <ActivityImage url="/uploads/24_Screenshot_20230123_214231_Gallery_913e1b15f1.webp?updated_at=2023-02-14T16:58:31.960Z" alt="wally1" /> 
      <ActivityImage url="/uploads/25_Screenshot_20230123_214242_Gallery_1ed7879757.webp?updated_at=2023-02-14T16:58:30.727Z" alt="wally2" />
      </div>
      <p><b>Make your own search and find activity at home</b></p>
      <p>Draw a handful of small, miscellaneous pictures on a page, and then have others search for a particular one, with each person taking turns designing a new page.</p>
      <p> This is a great way to inspire creativity in everyone.
      </p>











      

    </div>
  )
}

export default WhereisWally
