
import './App.css';
import Header from './components/header/Header';
import { useState, createContext} from 'react';
import ActivityDetail from './components/activity/ActivityDetail';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/home/Home';
import Search from './components/search/SearchBar';
import AnimalWrapping from './activities/animal-wrapping/animal-wrapping';
import PaperWeaving from './activities/paper-weaving/paper-weaving';
import ChristmasCookieDecorations from './activities/christmas-cookie-decorations/christmas-cookie-decorations';
import { useEffect } from 'react';
import RockPainting from './activities/rock-painting/rock-painting';
import StampArt from './activities/stamp-art/stamp-art';
import PaintPourArt from './activities/paint-pour-art/paint-pour-art';
import PaperFlipBookAnimation from './activities/paper-flip-book-animation/paper-flip-book-animation';
import FeltPuzzle from './activities/felt-puzzle/felt-puzzle';
import MakeaSnowman from './activities/make-a-snowman/make-a-snowman';
import IceExcavation from './activities/ice-excavation/ice-excavation';
import WaterBabyDecorations from './activities/water-baby-decorations/water-baby-decorations';
import DiamondPainting from './activities/diamond-painting/diamond-painting';
import MakeFridgeMagnets from './activities/make-fridge-magnets/make-fridge-magnets';
import ColorinToteBag from './activities/color-in-tote-bag/color-in-tote-bag';
import CompleteaMaze from './activities/complete-a-maze/complete-a-maze';
import FingerKnitting from './activities/finger-knitting/finger-knitting';
import WaterSiphonExperiment from './activities/water-siphon-experiment/water-siphon-experiment';
import IroningBeads from './activities/ironing-beads/ironing-beads';
import BuildaBridge from './activities/build-a-bridge/build-a-bridge';
import ColanderFineMotorActivity from './activities/colander-fine-motor-activity/colander-fine-motor-activity';
import MakeOrangeJuice from './activities/make-orange-juice/make-orange-juice';
import MusicalInstruments from './activities/musical-instruments/musical-instruments';
import PlayLudo from './activities/play-ludo/play-ludo';
import Limbo from './activities/limbo/limbo';
import ThreeDimensionalPuzzles from './activities/three-dimensional-puzzles/three-dimensional-puzzles';
import ScrapePainting from './activities/scrape-painting/scrape-painting';
import PaperFamily from './activities/paper-family/paper-family';
import AccordionPaperFolding from './activities/accordion-paper-folding/accordion-paper-folding';
import MixingColors from './activities/mixing-colors/mixing-colors';
import SendParcels from './activities/send-parcels/send-parcels';
import RoastMarshmallows from './activities/roast-marshmallows/roast-marshmallows';
import MakeWaffles from './activities/make-waffles/make-waffles';
import BeanBagActivities from './activities/bean-bag-activities/bean-bag-activities';
import ArtsandCrafts from './activities/arts-and-crafts/arts-and-crafts';
import AbacusGames from './activities/abacus-games/abacus-games';
import ActivitieswithaBall from './activities/activities-with-a-ball/activities-with-a-ball';
import TugofWar from './activities/tug-of-war/tug-of-war';
import OMO from './activities/omo/omo';
import WriteandDrawaStory from './activities/write-and-draw-a-story/write-and-draw-a-story';
import WoodenPegPuzzles from './activities/wooden-peg-puzzles/wooden-peg-puzzles';
import WhereisWally from './activities/where-is-wally/where-is-wally';
import WaterSquishToys from './activities/water-squish-toys/water-squish-toys';
import HandPaintActivities from './activities/hand-paint-activities/hand-paint-activities';
import HaveaTeaParty from './activities/have-a-tea-party/have-a-tea-party';
import WhatisintheBox from './activities/what-is-in-the-box/what-is-in-the-box';
import YourDreamsandGoals from './activities/your-dreams-and-goals/your-dreams-and-goals';
import WhatAmIThankfulFor from './activities/what-am-i-thankful-for/what-am-i-thankful-for';
import TreasureHuntwithaMap from './activities/treasure-hunt-with-a-map/treasure-hunt-with-a-map';
import TongsandTweezers from './activities/tongs-and-tweezers/tongs-and-tweezers';
import BuildHighStructures from './activities/build-high-structures/build-high-structures';
import PlayTelephone from './activities/play-telephone/play-telephone';
import BlowBubbles from './activities/blow-bubbles/blow-bubbles';
import PlayUNO from './activities/play-uno/play-uno';
import PlayStuckintheMud from './activities/play-stuck-in-the-mud/play-stuck-in-the-mud';
import WhoLaughsFirst from './activities/who-laughs-first/who-laughs-first';
import BuildaPyramid from './activities/build-a-pyramid/build-a-pyramid';
import StrawBlowingBubbles from './activities/straw-blowing-bubbles/straw-blowing-bubbles';
import PlaywithaFrisbee from './activities/play-with-a-frisbee/play-with-a-frisbee';
import ActionPaint from './activities/action-paint/action-paint';
import ActivitieswithaLargeBox from './activities/activities-with-a-large-box/activities-with-a-large-box';
import LearnAboutCountries from './activities/learn-about-countries/learn-about-countries';
import ToiletPaperMummy from './activities/toilet-paper-mummy/toilet-paper-mummy';
import TicTacToe from './activities/tic-tac-toe/tic-tac-toe';
import TenpinBowling from './activities/tenpin-bowling/tenpin-bowling';
import DraworWritewithBothHands from './activities/draw-or-write-with-both-hands/draw-or-write-with-both-hands';
import ChoresforPocketMoney from './activities/chores-for-pocket-money/chores-for-pocket-money';
import OatsandCoconutSnack from './activities/oats-and-coconut-snack/oats-and-coconut-snack';
import GuesstheDrawing from './activities/guess-the-drawing/guess-the-drawing';

export const FilterContext = createContext({selectedTypes: [], selectedAges: [], selectedAreas: []});

function App() {
  const [searchValue, setSearchValue] = useState('')
  const [filter, setFilter] = useState({selectedTypes: [], selectedAges: [], selectedAreas: []})
  return (
    <FilterContext.Provider value={{filter, setFilter}}>
    <div className="App">
      <Header
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      <Routes>
        <Route
          path="/"
          exact
          element={
            <Home
              searchValue={searchValue}
            />
          }
        />
        <Route path="/detail/:id" element={<ActivityDetail />}>
          <Route path="animal-wrapping" element={<AnimalWrapping />} />
          <Route path="paper-weaving" element={<PaperWeaving />} />
          <Route
            path="christmas-cookie-decorations"
            element={<ChristmasCookieDecorations />}
          />
          <Route path="rock-painting" element={<RockPainting />} />
          <Route path="stamp-art" element={<StampArt />} />
          <Route path="paint-pour-art" element={<PaintPourArt />} />
          <Route
            path="paper-flip-book-animation"
            element={<PaperFlipBookAnimation />}            
          />
          <Route path="felt-puzzle" element={<FeltPuzzle />} />
          <Route path="make-a-snowman" element={<MakeaSnowman />} />
          <Route path="ice-excavation" element={<IceExcavation />} />
          <Route path="water-baby-decorations" element={<WaterBabyDecorations />} />
          <Route path="diamond-painting" element={<DiamondPainting />} />
          <Route path="make-fridge-magnets" element={<MakeFridgeMagnets />} />
          <Route path="color-in-tote-bag" element={<ColorinToteBag />} />
          <Route path="complete-a-maze" element={<CompleteaMaze />} />
          <Route path="finger-knitting" element={<FingerKnitting />} />
          <Route path="water-siphon-experiment" element={<WaterSiphonExperiment />} />
          <Route path="ironing-beads" element={<IroningBeads />} />
          <Route path="build-a-bridge" element={<BuildaBridge />} />
          <Route path="colander-fine-motor-activity" element={<ColanderFineMotorActivity />} />
          <Route path="make-orange-juice" element={<MakeOrangeJuice />} />
          <Route path="musical-instruments" element={<MusicalInstruments />} />
          <Route path="play-ludo" element={<PlayLudo />} />
          <Route path="limbo" element={<Limbo />} />
          <Route path="three-dimensional-puzzles" element={<ThreeDimensionalPuzzles />} />
          <Route path="scrape-painting" element={<ScrapePainting />} />
          <Route path="paper-family" element={<PaperFamily />} />
          <Route path="accordion-paper-folding" element={<AccordionPaperFolding />} />
          <Route path="mixing-colors" element={<MixingColors />} />
          <Route path="send-parcels" element={<SendParcels />} />
          <Route path="roast-marshmallows" element={<RoastMarshmallows />} />
          <Route path="make-waffles" element={<MakeWaffles />} />
          <Route path="bean-bag-activities" element={<BeanBagActivities />} />
          <Route path="arts-and-crafts" element={<ArtsandCrafts />} />
          <Route path="abacus-games" element={<AbacusGames />} />
          <Route path="activities-with-a-ball" element={<ActivitieswithaBall />} />
          <Route path="omo" element={<OMO />} />
          <Route path="tug-of-war" element={<TugofWar />} />
          <Route path="write-and-draw-a-story" element={<WriteandDrawaStory />} />
          <Route path="wooden-peg-puzzles" element={<WoodenPegPuzzles />} />
          <Route path="where-is-wally" element={<WhereisWally />} />
          <Route path="water-squish-toys" element={<WaterSquishToys />} />
          <Route path="hand-paint-activities" element={<HandPaintActivities />} />
          <Route path="have-a-tea-party" element={<HaveaTeaParty />} />
          <Route path="what-is-in-the-box" element={<WhatisintheBox />} />
          <Route path="your-dreams-and-goals" element={<YourDreamsandGoals />} />
          <Route path="what-am-i-thankful-for" element={<WhatAmIThankfulFor />} />
          <Route path="treasure-hunt-with-a-map" element={<TreasureHuntwithaMap />} />
          <Route path="tongs-and-tweezers" element={<TongsandTweezers />} />
          <Route path="build-high-structures" element={<BuildHighStructures />} />
          <Route path="play-telephone" element={<PlayTelephone />} />
          <Route path="blow-bubbles" element={<BlowBubbles />} />
          <Route path="play-uno" element={<PlayUNO />} />
          <Route path="play-stuck-in-the-mud" element={<PlayStuckintheMud />} />
          <Route path="who-laughs-first" element={<WhoLaughsFirst />} />
          <Route path="build-a-pyramid" element={<BuildaPyramid />} />
          <Route path="straw-blowing-bubbles" element={<StrawBlowingBubbles />} />
          <Route path="play-with-a-frisbee" element={<PlaywithaFrisbee />} />
          <Route path="action-paint" element={<ActionPaint />} />
          <Route path="activities-with-a-large-box" element={<ActivitieswithaLargeBox />} />
          <Route path="learn-about-countries" element={<LearnAboutCountries />} />
          <Route path="toilet-paper-mummy" element={<ToiletPaperMummy />} />
          <Route path="tic-tac-toe" element={<TicTacToe />} />
          <Route path="tenpin-bowling" element={<TenpinBowling />} />
          <Route path="draw-or-write-with-both-hands" element={<DraworWritewithBothHands />} />
          <Route path="chores-for-pocket-money" element={<ChoresforPocketMoney />} />
          <Route path="oats-and-coconut-snack" element={<OatsandCoconutSnack />} />
          <Route path="guess-the-drawing" element={<GuesstheDrawing />} />
        </Route>

        <Route
          path="/search/:query"
          element={<Search searchValue={searchValue} />}
        />
      </Routes>
    </div>
    </FilterContext.Provider>
  );
}


export default App;
