import ActivityImage from '../../components/activity/activityImage'
import './paper-flip-book-animation.css'


function PaperFlipBookAnimation() {
 
  return (
    <div >

      <p><b>Instructions :</b></p>
    <ul>
      <li>Use a book to draw in or use a few pages that have been stapled or glued together to form a book.</li>
      <li>Draw the picture close to the edge of the page, starting at the back and change a few details when drawing the next picture on the page before that.</li>
      <li>If a dark color is used, then it will be easier to see the picture through the page that they are currently drawing on. That will enable them to draw the next picture more accurately and allowing only a small change per page.</li>
      <li>After the drawings on all the pages have been completed, close the book and gradually flip the pages one by one to create the animation.</li>
    </ul>

      <div className='image-group'>
      <ActivityImage url="/uploads/145_Screenshot_20221122_211509_Gallery_a0ebafda9c.jpg?updated_at=2023-01-29T19:23:51.561Z" alt="paperflipbook1" /> 
      <ActivityImage url="/uploads/144_Screenshot_20221122_211519_Gallery_edb3a1e523.webp?updated_at=2023-01-29T19:23:51.478Z" alt="paperflipbook2" />
      <ActivityImage url="/uploads/146_Screenshot_20221122_211506_Gallery_424bebb300.jpg?updated_at=2023-01-29T19:23:51.425Z" alt="paperflipbook3" />
      <ActivityImage url="/uploads/143_Screenshot_20221122_211536_Gallery_b43538091e.webp?updated_at=2023-01-29T19:23:51.110Z" alt="paperflipbook4" />
    
      </div>


      <p>Children can create their own animations while having fun and being creative!</p>






There are different animations that can be made
Kicking a ball
A fish swimming
A plant growing
A lightbulb being switched on
Going down a slide
A dog, cat and mouse chase
Blowing up a balloon
A character dancing
A bird flapping its wings
Write a word letter by letter and many more!









      

    </div>
  )
}

export default PaperFlipBookAnimation
