import ActivityImage from '../../components/activity/activityImage'
import './what-am-i-thankful-for.css'


function WhatAmIThankfulFor() {
 
  return (
    <div >
      <p>Children can be taught to think about the things that they have in life and learn to appreciate it.</p> 

<p>Take turns naming things to be thankful for no matter how small. They can even discuss it a bit.</p>

<p>Children can make drawings of the things that they appreciate and put it up on walls or stick it on the fridge as a reminder. It allows them to take a moment and reflect on everything.</p>











      

    </div>
  )
}

export default WhatAmIThankfulFor
