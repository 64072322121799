import { useEffect, useState } from "react";
import Activity from "./Activity";
import "./activityList.css";

import MeiliSearch from "meilisearch";
import { useRef, useCallback } from "react";

function ActivityList({ searchValue , excludeId}) {
  const [Activities, setActivities] = useState([]);
  const observerElem = useRef(null);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [offset, setOffset] = useState(0);
  const [lastPage, setLastPage] = useState({});

  const fetchData = async (searchStr = "") => {
    const client = new MeiliSearch({
      host: "https://api.artsandparenting.com/meilli", // "https://ms-53118596bd8e-1995.lon.meilisearch.io",
      apiKey: "996c07ef10385217a247b3577edd2013534b742a"
    });
    const index = await client.getIndex("activity");
    if (searchStr === "") {
      searchStr = "*";
    }
    const ActivitiesData = await index.search(searchStr, {
      limit: 10,
      offset: offset,
    });
    if(Activities && excludeId) {
      const filtered = Activities.filter((activity) => activity.id !== excludeId);
      setActivities([...filtered, ...ActivitiesData.hits]);
    } else {
      setActivities([...Activities, ...ActivitiesData.hits]);
    }
    setActivities([...Activities, ...ActivitiesData.hits]);
    setLastPage(ActivitiesData);
  };
  useEffect(() => {
    fetchData(searchValue);
  }, [searchValue]);

  useEffect(() => {
    setOffset(Activities.length);
    if (Activities.length < lastPage.estimatedTotalHits) {
      console.log("has next page", lastPage);
      setHasNextPage(true);
    } else {
      console.log("no next page");
      setHasNextPage(false);
    }

  }, [Activities]);

  const handleObserver = useCallback(
    (entries) => {
      const [target] = entries;
      if (target.isIntersecting && hasNextPage) {
        fetchData();
      }
    },
    [fetchData, hasNextPage]
  );

  useEffect(() => {
    const element = observerElem.current;
    const option = { threshold: 0 };
    const observer = new IntersectionObserver(handleObserver, option);
    observer.observe(element);
    return () => observer.unobserve(element);
  }, [hasNextPage, handleObserver]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="moreActivities">
      <div className="allActivities wrapper">
        <h2>More Activities</h2>
        <div className="Activities">
          {Activities?.filter((activity) => activity.id !== excludeId).map((activity) => (
            <Activity
              key={activity.id}
              title={activity.title}
              subtitle={activity.subtitle}
              cover={activity.cover}
              type={activity.activity_types}
              ages={activity.ages}
              id={activity.id}
              filename={activity.filename}
            />
          ))}
        </div>

        <div className="loader" ref={observerElem}>
          {Activities.length !== 0 && (
            <span>{hasNextPage ? "Loading..." : "no Activities left"}</span>
          )}
        </div>
      </div>
    </div>
  );
}

export default ActivityList;
