import ActivityImage from '../../components/activity/activityImage'
import './rock-painting.css'


function RockPainting() {
 
  return (
    <div >
      <p>They can turn ordinary stones into colorful and beautiful works of art that can be displayed in your garden or given as gifts.</p>
      <p>The picture can be drawn in pencil beforehand or it can be painted right away.</p>
      <p><b>Things that can be painted :</b> Bugs, houses, vehicles, faces, animals, dotted art.</p>
      <div className='image-group'>
      <ActivityImage url="/uploads/298_Screenshot_2022_11_05_164336_c40239f1d0.jpg?updated_at=2023-01-29T19:23:30.341Z" alt="animal rock painting" /> 
      <ActivityImage url="/uploads/297_Screenshot_2022_11_05_164513_a061119af5.jpg?updated_at=2023-01-29T19:23:30.220Z" alt="bug rock painting" />
      <ActivityImage url="/uploads/296_Screenshot_2022_11_05_164604_9ef8115514.jpg?updated_at=2023-01-29T19:23:30.145Z" alt="vechile rock painting" />
      <ActivityImage url="/uploads/295_Screenshot_2022_11_05_164653_e59cb647b0.jpg?updated_at=2023-01-29T19:23:30.068Z" alt="building rock painting" />
      <ActivityImage url="/uploads/294_Screenshot_2022_11_05_164736_df34abf4ee.jpg?updated_at=2023-01-29T19:23:29.937Z" alt="face rock painting" />
      </div>
      <p>To achieve more vibrant colors, consider painting the rocks with a white first. Acrylic paint dries quickly and shows better on rocks than watercolors, making it a great option for rock painting.
      </p>











      

    </div>
  )
}

export default RockPainting
