import './header.css'
import SearchBar from '../search/SearchBar'
import {useNavigate} from 'react-router-dom'
import Multiselect from 'multiselect-react-dropdown';
import MeiliSearch from "meilisearch";
import { useEffect, useState, useContext } from 'react';
import { FilterContext } from '../../App';

function Header({searchValue, setSearchValue}) {
  const {filter, setFilter} = useContext(FilterContext)

  const [types, setTypes] = useState([]);
  const [showTitle, setShowTitle] = useState(true)
  const [ages, setAges] = useState([]);
  const [areas, setAreas] = useState([]);
  const client = new MeiliSearch({
    host: "https://api.artsandparenting.com/meilli",//"https://ms-53118596bd8e-1995.lon.meilisearch.io",
    apiKey: "996c07ef10385217a247b3577edd2013534b742a"
  });
  const page = window.location.pathname.split('/')[1]
  let showNavBar = page === '' ? true : false


  useEffect( () => {
    const fetchTypes = async () => {

      const index = await client.getIndex("activity-type")
      const response = await index.search('*')
      const fetchedTypes = response.hits.map((type) => {
        return {name: type.name, id: type.id}
      })

      setTypes(fetchedTypes) 
    }
    fetchTypes()

    const fetchAges = async () => {
      const index = await client.getIndex("age")
      const response = await index.search('*')
      const fetchedAges = response.hits.map((age) => {
        return {name: age.range, id: age.id}
      })
      setAges(fetchedAges)
    }
    fetchAges()
    
    const fetchAreas = async () => {
      const index = await client.getIndex("area")
      const response = await index.search('*')
      const fetchedAreas = response.hits.map((area) => {
        return {name: area.name, id: area.id}
      })
      setAreas(fetchedAreas)
    }
    fetchAreas()
  
  }, [])

  useEffect(() => {
    if (
      filter.selectedTypes.length > 0 ||
      filter.selectedAges.length > 0 ||
      filter.selectedAreas.length > 0
    ) {
      setShowTitle(false);
    } else {
      setShowTitle(true);
    }

  }, [filter])


  const onTypeSelect = (selectedList, _selectedItem) => {
    setFilter({...filter, selectedTypes: selectedList})
  }
  
  const onTypeRemove = (selectedList, _removedItem) => {
    setFilter({...filter, selectedTypes: selectedList})
  }

  const onAgeSelect = (selectedList, _selectedItem) => {
    setFilter({...filter, selectedAges: selectedList})
  }

  const onAgeRemove = (selectedList, _removedItem) => {
    setFilter({...filter, selectedAges: selectedList})
  }

  const onAreaSelect = (selectedList, _selectedItem) => {
    setFilter({...filter, selectedAreas: selectedList})
  }

  const onAreaRemove = (selectedList, _removedItem) => {
    setFilter({...filter, selectedAreas: selectedList})
  }
  
  
  const navigate = useNavigate()
  const style =  { 
    multiselectContainer: {
      'fontSize': '10px',
    },
    searchBox: { 
      'borderRadius': '15px',
      'fontSize': '10px',
      'minHeight': '10px',
    },
    inputField: { 
        margin: '2px',
    },
    chips: {
      'fontSize': '10px',
    },
  }
  console.log('showNavBar', showNavBar)
  const wrapperClass = showNavBar ? "wrapper" : "wrapper-small"
  return (
    <header>
      <div className={wrapperClass}>
        {showTitle && <div className="logo" onClick={() => navigate("/")}>
          <h2>
            <img src="https://api.artsandparenting.com/uploads/small_487_Screenshot_20221015_214904_9ecce24e2e.webp" alt="logo" />
            &nbsp; Arts and Parenting
          </h2>
        </div>}
        { showNavBar && <div className="nav-bar">
          <SearchBar
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
          <div className="filter-bar">
            <Multiselect
              options={types}
              onSelect={onTypeSelect}
              onRemove={onTypeRemove}
              displayValue="name"
              placeholder="Filter by type"
              selectionLimit={3}
              style={style}
            />
            <Multiselect
              options={ages}
              onSelect={onAgeSelect}
              onRemove={onAgeRemove}
              displayValue="name"
              placeholder="Filter by age"
              selectionLimit={3}
              style={style}
            />
            <Multiselect
              options={areas}
              onSelect={onAreaSelect}
              onRemove={onAreaRemove}
              displayValue="name"
              placeholder="Filter by area"
              selectionLimit={3}
              style={style}
            />
          </div> 
        </div>}
      </div>
    </header>
  );
}



export default Header
