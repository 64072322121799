import ActivityImage from '../../components/activity/activityImage'
import './who-laughs-first.css'


function WhoLaughsFirst() {
 
  return (
    <div >
      <p>This is a fun game that can be played with any number of players, making it perfect for family game nights or playdates with friends.</p>
       <p> <b>The rules are simple:</b> one player starts by telling a joke, doing a silly dance, or making a funny face, while the other players try their best not to laugh.</p> 
       <p> The first player to laugh loses and becomes the new "jester" who must come up with a new joke or silly action to try and make the other players laugh. </p>
        <p>"Who Laughs First" is sure to be a hit with kids of all ages!</p>
      











      

    </div>
  )
}

export default WhoLaughsFirst
