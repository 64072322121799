import ActivityImage from '../../components/activity/activityImage'
import './tic-tac-toe.css'


function TicTacToe() {
 
  return (
    <div >
      <p> All you need is a piece of paper and a pencil or pen to get started.</p>

<p><b>Rules:</b>
  <ul>
    <li>To play, you'll need two players.</li>
    <li>One player will be "O" and draw circles, while the other player will be "X" and draw crosses.</li>
    <li>Each player takes turns drawing their shape in a block on the paper until one player gets three in a row. </li>
    <li>The first player to get three in a row, either horizontally, vertically or diagonally, gets to make a strikethrough and wins the game!</li>
    </ul>  
    <div className='image-group'>
      <ActivityImage url="/uploads/Screenshot_20220914_212012_Gallery_300x294_4b2776edd0.jpg?updated_at=2023-03-21T15:44:55.203Z" alt="tictactoe1" /> 
      <ActivityImage url="/uploads/Screenshot_20220914_212010_Gallery_300x297_ab58158815.jpg?updated_at=2023-03-21T15:45:13.300Z" alt="tictactoe2" />
      <ActivityImage url="/uploads/Screenshot_20220914_212006_Gallery_300x294_7b74b45683.jpg?updated_at=2023-03-21T15:45:17.445Z" alt="tictactoe3" />
      <ActivityImage url="/uploads/Screenshot_20220914_211958_Gallery_297x300_336be29f11.jpg?updated_at=2023-03-21T15:45:21.920Z" alt="tictactoe4" />
      <ActivityImage url="/uploads/Screenshot_20220914_211956_Gallery_300x298_aed6638531.jpg?updated_at=2023-03-21T15:45:25.949Z" alt="tictactoe5" />
      </div>

<p><b>But wait, there's more! </b></p>
You can also use different colors instead of shapes to add some extra flair to the game. For example, one player can use red and the other can use blue. This way, you can make your Tic Tac Toe game even more colorful and exciting.

<p>If you want to make the game even more challenging, you can draw a larger grid with more blocks and keep taking turns playing. The person with the most strikethroughs at the end wins!</p>
<div className='image-group'>
      <ActivityImage url="/uploads/Screenshot_20220914_211950_Gallery_768x777_947d360d2b.jpg?updated_at=2023-03-21T15:45:30.024Z" alt="tictactoe6" /> 
      <ActivityImage url="/uploads/Screenshot_20220914_211948_Gallery_768x780_51070e229b.jpg?updated_at=2023-03-21T15:45:39.594Z" alt="tictactoe7" />
      <ActivityImage url="/uploads/Screenshot_20220914_211941_Gallery_297x300_e55f25a8c5.jpg?updated_at=2023-03-21T15:45:42.249Z" alt="tictactoe8" />
      <ActivityImage url="/uploads/Screenshot_20220914_211926_Gallery_297x300_75fd1245db.jpg?updated_at=2023-03-21T15:45:45.441Z" alt="tictactoe9" />
      </div>

<p>Overall, Tic Tac Toe is a super fun game that is perfect for kids of different ages. It's easy to set up, quick to play, and can provide hours of entertainment. So grab a friend or family member, grab a piece of paper and pencil, and get ready to have a blast playing Tic Tac Toe!</p>
      </p>











      

    </div>
  )
}

export default TicTacToe
