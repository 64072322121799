import ActivityImage from '../../components/activity/activityImage'
import './tongs-and-tweezers.css'


function TongsandTweezers() {
 
  return (
    <div >
      <p>This activity can be done with a variety of items.</p>
      <p>Put toys on a table or inbetween something  like pasta.  The pasta can be cooked in colouerd water so make it look interesting.
    Children can pick out the items with tweezers.</p>
      <ActivityImage url="/uploads/Screenshot_20220908_060948_Gallery_768x776_1f1f7039f2.jpg?updated_at=2023-03-04T18:43:42.099Z" alt="tongs1" /> 
      <p>You can  make a bucket of jelly and add the toys before it sets.</p>
      
      
      <ActivityImage url="/uploads/Screenshot_20220908_061101_Gallery_768x764_71c9d7fc4c.jpg?updated_at=2023-03-04T18:43:47.365Z" alt="tongs2" />
     
      <p>Toys can be put on the table and grabbed with the tweezers and trasferred into another container.</p>
      <p>You can also have containers of different colors and let them sort the toys according to color when picking it up.</p>

      


















      

    </div>
  )
}

export default TongsandTweezers
