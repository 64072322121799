import ActivityImage from '../../components/activity/activityImage'
import './ice-excavation.css'


function IceExcavation() {
 
  return (
    <div >
      <p>Place the toys in a bucket of water and place it in the freezer overnight. A little bit of food coloring can be added to the water.</p>
      <p><b>Tip: </b> use toys that float and others that sink to ensure even distribution in the ice block.</p>
      
      <div className='image-group'>
      <ActivityImage url="/uploads/53_Screenshot_20230103_190004_Gallery_6b8cf1fad7.webp?updated_at=2023-01-29T19:23:57.869Z" alt="iceexcavation1" /> 
      <ActivityImage url="/uploads/52_Screenshot_20230103_190043_Gallery_17b61b679d.webp?updated_at=2023-01-29T19:23:57.684Z" alt="iceexcavation2" />
      <ActivityImage url="/uploads/51_Screenshot_20230103_190825_Gallery_eb1d21303f.jpg?updated_at=2023-01-29T19:23:56.853Z" alt="iceexcavation3" />
      </div>
      











      

    </div>
  )
}

export default IceExcavation
