import ActivityImage from '../../components/activity/activityImage'
import './activities-with-a-ball.css'


function ActivitieswithaBall() {
 
  return (
    <div >
       
   
    <h3>Benefits of these activities:</h3>
    <ul>
      <li>Improved coordination and balance</li>
      <li>Increased flexibility and agility</li>
      <li>Improved cognitive function and memory</li>
      <li>Increased self-esteem and confidence</li>
      <li>Improved teamwork and social skills</li>
      <li>Increased overall physical activity and decreased sedentary behavior</li>
      <li>Fun and enjoyment</li>
      <li>Developing gross motor skills</li>
      <li>Enhancing problem-solving and decision-making abilities</li>
      <li>Building a healthy habit of regular exercise</li>
      <li>Enhancing self-discipline and focus</li>
      <li>Improving reaction time</li>
      <li>Enhancing spatial awareness</li>
      <li>Improving visual-spatial coordination</li>
      <li>Encouraging creativity and imagination</li>
    </ul>
    <h3>Activities with a Ball:</h3>
   
      <p><b>#1</b>   Learn to dribble using hands or feet.</p>
    
      <p><b>#2</b>   Spin the ball on one finger. Start by using both hands to get control over the spinning. Once that has been mastered one hand can be used.</p> 
      
      <p><b>#3</b>   Roll the ball from one hand to the other hand.</p>
      
      <p><b>#4</b>   See how many times you can bounce the ball on your leg. Once that skill have been mastered it can be bounced from one leg to the other.</p>
    
      <p><b>#5</b>   Throw the ball to someone else and increase the distance after every catch. It can be a three player game. One person will stay in position and take turns throwing the ball to the other two players. Each time one of them catches the ball they can move one step backwards. If they miss then they can move one step forward.</p>
    
      <p><b>#6</b>   Play middleman. Everyone stand in a line. The two people on the sides will try to throw, roll or bounce the ball to the other person while the other people try to catch it. The person that catches the ball, swaps places with the person on the side.</p>
     
      <p><b>#7</b>   Shoot hoops. One can be bought or made at home. Use a hula hoop, a box open at the bottom or even a bucket, anything that the ball can land in. You can mount it on a wall or tree or just place it on an elevated area.</p>
      
      <p><b>#8</b>   Combine the dribble and hoops and play basketball.</p>
      
      <p><b>#9</b>   Aim at a target. Put up a marker and aim for it. It is a good way to learn coordination</p>.
     
      <p><b>#10</b>  Target practice by kicking the ball.</p>
     
      <p><b>#11</b>  Passing the ball while running.</p>
      
      <p><b>#12</b>  Play dodgeball. A game for 2 players and up. Divide into two teams. Stand a fair distance from each other. The aim is to hit someone from the other team by throwing a ball while they duck or jump to avoid being hit. It is recommended to aim below the waist to avoid injury. One you have hit someone, they are out for the round. The last person or team standing, wins.</p>
 










      

    </div>
  )
}

export default ActivitieswithaBall