import ActivityImage from '../../components/activity/activityImage'
import './play-stuck-in-the-mud.css'


function PlayStuckintheMud() {
 
  return (
    <div >
      <p>Stuck in the mud is a game that can be played by 3 or more players.</p>
      <p>It is a game of tag where the person who is "it" has to chase the other players and tag them.</p>
      
      
      <p>Once a player is tagged, they become "stuck in the mud" and can only be freed if another player crawls under their legs.</p>
      <p>The rescuer must wait until "it" is a safe distance away before attempting to free the trapped player.</p> 
      <p>The game continues until all players are stuck, at which point the next person becomes "it".</p>
      
      <p>It is a fun game that can be played indoors or outdoors.</p>
      <p>It is also a good way to get children to exercise.</p>


     






      

    </div>
  )
}

export default PlayStuckintheMud
