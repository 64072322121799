import "./activity.css";
import { useNavigate } from "react-router-dom";
import {
  FacebookShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  TwitterShareButton,
  FacebookIcon,
  WhatsappIcon,
  PinterestIcon,
  TwitterIcon,
} from "react-share";
import Modal from 'react-modal';
import { useState, useContext } from 'react'
import { FilterContext } from '../../App'



const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {zIndex: 1000}
};


function Activity({
  title,
  subtitle,
  cover,
  activity_types = [],
  ages = [],
  id,
  filename,
  onClick = () => {},
}) {
  const {_filter, setFilter} = useContext(FilterContext)
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function handleClick(event) {
    console.log(event.target);
    setFilter({selectedTypes: [], selectedAges: [], selectedAreas: []})
    navigate(`/detail/${id}/${filename}`)
  }

  const navigate = useNavigate();
  Modal.setAppElement('#root');
  let resource = cover?.formats?.small?.url;
  if (cover?.formats?.small?.url === undefined) {
    resource = cover?.url;
  }
  if(resource === undefined){
    resource = "/uploads/Screenshot_20221015_214904_1_1_768x697_60bf93a7ee.webp"
  }
  const shareUrl = 'http://artsandparenting.com/detail/'+id+'/'+filename
  return (
    <div
      className="card-activity-container"
      >
         <div className="activity-share-icon" onClick={openModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-share-2"
            >
              <circle cx="18" cy="5" r="3"></circle>
              <circle cx="6" cy="12" r="3"></circle>
              <circle cx="18" cy="19" r="3"></circle>
              <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
              <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
            </svg>
          </div>
          <Modal
           
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Share this Activity..."

          >
            <div className="activity-share-title">
              <h3>Share this Activity</h3>
            </div>
              <div className="activity-share-buttons">
                <FacebookShareButton url={shareUrl}>
                  <FacebookIcon size={34} round />
                </FacebookShareButton>
                <WhatsappShareButton url={shareUrl}>
                  <WhatsappIcon size={34} round />
                </WhatsappShareButton>
                <PinterestShareButton media={shareUrl} url={shareUrl}>
                  <PinterestIcon size={34} round />
                </PinterestShareButton>
                <TwitterShareButton url={shareUrl}>
                  <TwitterIcon size={34} round />
                </TwitterShareButton>
              </div>
          </Modal>
      <div className="card-activity-post" >
        <div className="card-activity-image" onClick={(event) => handleClick(event)}>
          <img src={"https://api.artsandparenting.com" + resource} alt="activity" />
        </div>
       
        <div className="card-activity-info">
          <h1>{title}</h1>
        </div>
      </div>
    </div>
  );
}

export default Activity;
