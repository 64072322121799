import ActivityImage from '../../components/activity/activityImage'
import './play-uno.css'


function PlayUNO() {
 
  return (
    <div >
     <p>The cards 
      <ul>
       <li>1 to 9 - the number cards</li>
       <li>Arrows – change the direction of play from clockwise to counter clockwise until another set of arrows are played.</li>
       <li>Skip – the next player skips a turn.</li>
       <li>Wild – the player that puts down the card can choose a new color to be played.</li>
       <li>Draw 2 – the next player must pick up 2 cards.</li>
       <li>Wild draw 4 – the next player must pick up 4 cards.</li>
      </ul>
     </p>
     <ActivityImage url="/uploads/Screenshot_20221012_112303_300x300_ad62d74d91.jpg?updated_at=2023-03-05T14:49:24.063Z" alt="uno1" /> 


     <p><b>Game #1 is a simple version </b>
       <ul>
        <li>Shuffle all the cards and deal 7 cards to each player.</li>
        <li>The first person to play all their cards, wins.</li>
        <li>Everyone will take turns to put down 1 card at a time.</li>
        <li>You may play the same color or the same symbol.</li>
        <li>If you do not have card to play then draw one from the deck.</li>
        <li>Each time a player has one card left they must say UNO out loud before someone else can say it for them otherwise they must pick up another card.</li>        
        <li><b>Variations to the game: </b> The plus 2 and plus 4 may stack, which means that if you need to pick up cards you may add yours to the pile so that the next person has to pick up the sum of the number on the cards.</li></ul>
      </p>

       <p><b>Game #2 as per the game rules.</b>
          <ul>
            <li>The winner is the first player to reach 500 points.</li>
            <li>If you are the first to get rid of all your cards, you get points for the cards left in the other players hands.</li>
            <li>Calculation of points: All number cards (0-9) are worth the face value and the rest (reverse, skip, wild, wild draw 4) are worth 20 points.</li>
            </ul>
       </p>

      <p><b>Make your own cards at home</b></p>
         <p>Use some paper or paper stuck on carton (like a cereal box) and color it in according to the cards in the deck. If you only have paper and prefer a thicker card, paste the pages on another piece of paper. It will make it slightly thicker. Cards can be laminated afterwards to make it last longer.</p>

<div className='image-group'>
      <ActivityImage url="/uploads/Screenshot_20221024_202043_300x247_3cfcfeda0b.jpg?updated_at=2023-03-05T14:49:28.349Z" alt="uno2" /> 
      <ActivityImage url="/uploads/Screenshot_20221024_202030_300x271_9e49a6c8fc.jpg?updated_at=2023-03-05T14:49:31.591Z" alt="uno3" />
      </div>
      <p>Have fun!</p>
      


      










      

    </div>
  )
}

export default PlayUNO
