import ActivityImage from '../../components/activity/activityImage'
import './ironing-beads.css'


function IroningBeads() {
 
  return (
    <div >
      <p><b>Step 1: Create a pattern on the plastic bead holder and iron the top part. Be careful with the hot iron and children in the vicinity.</b></p>
      <div className='image-group'>
      <ActivityImage url="/uploads/215_Screenshot_20221110_145959_1_ba854587ea.jpg?updated_at=2023-01-29T19:23:36.565Z" alt="beads1" /> 
      <ActivityImage url="/uploads/214_Screenshot_20221110_150005_Gallery_eba3ca2766.jpg?updated_at=2023-01-29T19:23:36.517Z" alt="beads2" />   
      </div>
      <p><b>Step 2: Place the wax paper on top of the design.</b></p>
      <ActivityImage url="/uploads/213_Screenshot_20221110_150025_Gallery_623d5df1a3.jpg?updated_at=2023-01-29T19:23:36.323Z" alt="beads3" />
      <p><b>Step 3: Iron until the beads melt into each other.</b></p>
      <div className='image-group'>
      <ActivityImage url="/uploads/212_Screenshot_20221110_150040_3c0dea2025.jpg?updated_at=2023-01-29T19:23:36.085Z" alt="beads4" />
      <ActivityImage url="/uploads/211_Screenshot_20221110_150101_3b8f070d24.jpg?updated_at=2023-01-29T19:23:36.059Z" alt="beads5" />
      </div>
      <p><b>Step 4: Remove the wax wrap.</b></p>
      <p>Be careful as the beads might still be hot.</p>
      <ActivityImage url="/uploads/210_Screenshot_20221110_150120_Gallery_1_1cf53fdcf9.webp?updated_at=2023-01-29T19:23:36.493Z" alt="beads6" />

      <p>Children can place the beads in the holder and an adult can do the ironing. Little children should be supervised to ensure that they do not swallow the beads. </p>











      

    </div>
  )
}

export default IroningBeads
