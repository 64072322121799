import ActivityImage from '../../components/activity/activityImage'
import './make-orange-juice.css'


function MakeOrangeJuice() {
 
  return (
    <div >
      <p>This activity might encourage children to drink more orange juice as it is something that they have made for themselves. It is also good for fine motor development. There are different tools that can be used like a hand juicer, a blender or the orange can be placed on a fork and turned while squeezing out the juice. Messy but effective!</p>
  
      <ActivityImage url="/uploads/309_Screenshot_20221103_195554_b770823663.webp?updated_at=2023-01-29T19:23:23.822Z" alt="orangejuice1" />
    
     

    </div>
  )
}

export default MakeOrangeJuice
