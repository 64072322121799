import './christmas-cookie-decorations.css'
import ActivityImage from '../../components/activity/activityImage'

function ChristmasCookieDecorations() {
 
  return (
    <div >
      <p> If children only want to decorate the cookies then the decorating kits can be bought in advance. There are 2 cookie recipes included below should they prefer to also bake the cookies. </p>
      <p><b>Recipe #1:</b> Easy 7 ingredient cookies</p> 
      <p>Ingredients:</p>
      <ul>
        <li>3 cups flour (750ml)</li>
        <li>1 cup sugar</li>
        <li>250g butter (260ml)</li>
        <li>2 eggs</li>
        <li>3 teaspoons baking powder (7.5ml)</li>
        <li>Pinch of salt</li>
        <li>5ml vanilla</li>
      </ul>

      <p>Method:</p>
      <ul>
        <li>Mix the butter and sugar together</li>
        <li>Sieve the dry ingredients, add the butter, sugar and vanilla</li>
        <li>Knead the mixture and roll it out. Press out the shapes with a cookie cutter</li>
        <li>Place on a baking tray and bake at 160°C for 10 minutes or until brown</li>
      </ul>

      <p><b>Recipe #2:</b> Tasty spice cookies</p>
      <p>Ingredients:</p>
      <ul>
        <li>6 cups flour (1500ml)</li>
        <li>5ml baking soda</li>
        <li>625ml sugar</li>
        <li>5ml salt</li>
        <li>5ml ground cloves</li>
        <li>5ml cinnamon</li>
        <li>5ml cream of tartar</li>
        <li>5ml mixed spice</li>
        <li>500ml margarine/butter (470g)</li>
        <li>4 eggs</li>
        <li>1.5 tablespoon brandy (22ml)</li>
      </ul>

      <p>Method:</p>
      <ul>
        <li>Sift all the dry ingredients together</li>
        <li>Add the margarine/butter and cut it in with a knife until the pieces are small and evenly distributed</li>
        <li>Add the eggs and brandy, stir it into the mixture then knead the mixture until it forms a dough</li>
        <li>Roll small pieces of the dough out thin and cut of pieces to place on a baking tray</li>
        <li>Bake at 200°C for 12-15 minutes</li>
      </ul>

      <p><b>Buttercream icing recipe</b></p>
      <ul>
        <li>1 cup unsalted butter, softened to room temperature (white margarine can be used for a snowy white frosting)</li>
        <li>4 cups icing sugar</li>
        <li>1/2 cup milk or heavy cream</li>
        <li>2 tsp vanilla</li>
      </ul>

      <p>Beat the butter with an electric mixer on medium speed until smooth and creamy. Add in the icing sugar, milk and vanilla extract. Beat on low speed until the ingredients are well combined, then increase the speed to medium-high and beat until the icing is smooth and fluffy (3-5 minutes)</p>
      <div className='image-group'>
        <ActivityImage url="/uploads/82_Screenshot_20221216_124420_Gallery_5732e62039.jpg?updated_at=2023-01-29T19:23:52.973Z" alt="cookie decoration step 1" />
        <ActivityImage url="/uploads/81_Screenshot_20221216_124443_Gallery_ce2c9c8789.jpg?updated_at=2023-01-29T19:23:52.944Z" alt="cookie decoration step 1" />
        <ActivityImage url="/uploads/80_Screenshot_20221216_124406_Gallery_6f4e6dfb53.webp?updated_at=2023-01-29T19:23:53.348Z" alt="cookie decoration step 1" />
      </div>
      <div className='image-group'>
        <ActivityImage url="/uploads/79_Screenshot_20221216_124556_Gallery_c2f8921588.webp?updated_at=2023-01-30T07:11:38.344Z" alt="cookie decoration step 1" />
        <ActivityImage url="/uploads/78_Screenshot_20221216_131107_Gallery_bcafcc687d.webp?updated_at=2023-01-29T19:23:52.594Z" alt="cookie decoration step 1" />
        <ActivityImage url="/uploads/77_Screenshot_20221216_125612_Gallery_5a982230f8.jpg?updated_at=2023-01-29T19:23:52.229Z" alt="cookie decoration step 1" />
      </div>
    </div>
  )
}

export default ChristmasCookieDecorations
