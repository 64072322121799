import ActivityImage from '../../components/activity/activityImage'
import './play-ludo.css'


function PlayLudo() {
 
  return (
    <div >
      <p>Ludo is a board game played by 2-4 people.</p>
       
      <ul>
       <li>The goal is to move all your pieces from the start to the finish area in the middle of the board.</li>
       <li>Each player has 4 pieces and rolls a die to see how far to move clockwise around the board.</li>
       <li>You can have more than one piece on the board at the same time.</li>
       <li>If a player lands on a space with another player's piece, that piece goes back to the start.</li>
       <li>The winner is the first person to get all their pieces to the finish.</li>
      </ul>

     <p><b>Make your own Ludo game</b></p>
     <p>Paste a square paper on cardboard and draw the board. The colors can be added afterwards. The pieces can be made from paper and toys can be used that fit into the blocks. If you do not have a dice, write no 1-6 multiple times on a paper. With each turn, the player must close their eyes and press with their finger on the paper. The number closest to their finger will be used.</p>

      <p>Remember, Ludo is a game of luck and strategy, so have fun and enjoy the competition!</p>











      

    </div>
  )
}

export default PlayLudo
