import ActivityImage from '../../components/activity/activityImage'
import './build-high-structures.css'


function BuildHighStructures() {
 
  return (
    <div >
     <p>Any building materials can be used like blocks, books and containers. They can build the highest, strongest, or the most creative tower.</p>
     <ul>
     <li>Building high structures  can enhance a child's fine motor skills, hand-eye coordination, and spatial awareness.</li>
     <li>It encourages creativity and imagination by allowing them to explore and experiment with different designs and ideas.</li>
    <li>It improves problem-solving skills, as they must determine the stability of their design and adjust as needed.</li>
    <li>It can also promote social skills by encouraging children to work together and collaborate on building structures.</li>
    <li>It provides a sense of accomplishment and pride in their work.</li>
     </ul>
 



      <div className='image-group'>
      <ActivityImage url="/uploads/Screenshot_20220928_211246_15ecc3dae6.jpg?updated_at=2023-03-04T19:03:50.889Z" alt="highstructures1" /> 
      <ActivityImage url="/uploads/Screenshot_20220928_211356_768x824_727a9b6629.jpg?updated_at=2023-03-04T19:03:55.998Z" alt="highstructures2" />
     
      </div>
     
   <p>They can build a structure together or race each other to the top.</p>










      

    </div>
  )
}

export default BuildHighStructures
