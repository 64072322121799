import ActivityImage from '../../components/activity/activityImage'
import './hand-paint-activities.css'


function HandPaintActivities() {
 
  return (
    <div >
    
    <p>Advantages of hand painting: </p>
<ul>
  <li>Boosts creativity by expressing themselves</li>
  <li>Improves fine motor and problem-solving skills</li>
  <li>They feel a sense of pride and accomplishment</li>
  <li>Reduces stress and improves mental wellbeing as it is enjoyable</li>
</ul>
<p>This activity can be a lot of fun, but it can also get a bit messy, so it's a good idea to have kids wear an apron or use a bin bag to cover up. There are a few ways to create hand paintings - one is to paint their hands and press them onto the paper to make a picture, or you can simply drip some paint on their hands and let them rub them together. Just make sure to have a cloth or tap nearby to clean their hands before they move on to the next activity!</p>



      <div className='image-group'>
      <ActivityImage url="/uploads/Screenshot_20221014_204111_Gallery_2_768x665_68fbff627a.jpg?updated_at=2023-03-02T19:28:20.077Z" alt="handpaint1" /> 
      <ActivityImage url="/uploads/Screenshot_20221014_204130_1_768x620_53269a3fca.jpg?updated_at=2023-03-02T19:28:26.417Z" alt="handpaint2" />
      <ActivityImage url="/uploads/20221203_164833_1_300x300_62884d25bf.webp?updated_at=2023-03-02T19:28:30.611Z" alt="handpaint3" />
    
      </div>
      











      

    </div>
  )
}

export default HandPaintActivities
